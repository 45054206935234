.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-justify {
    text-align: justify;
}

.hide {
    display: none;
}

.space-between {
    justify-content: space-between;
}

.align-center {
    align-items: center;
}

.flex-1 {
    flex: 1;
}

.flex-2 {
    flex: 2;
}

.flex-3 {
    flex: 3;
}

.flex-4 {
    flex: 4;
}

.flex-5 {
    flex: 5;
}

.flex-auto {
    flex: 1 1 auto;
}

.flex {
    display: flex;
}

.flex-nowrap {
    flex-wrap: nowrap;
}

.flex-align-center {
    align-items: center;
}

.flex-justify-center {
    justify-content: center;
}

.flex-justify-end {
    justify-content: flex-end;
}

.flex-justify-between {
    justify-content: space-between;
}

.transparent {
    opacity: 0;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-auto {
    overflow: auto !important;
}

[hidden] {
    display: none !important;
}

.pointer {
    cursor: pointer;
}

.no-scroll {
    overflow: hidden !important;
}

.full-height {
    height: 100%;
}

.full-width {
    width: 100%;
}

.static-image {
    max-width: none !important;
}

.ft2-striped-background {
    &:before {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        opacity: 0.05;
        background-image: url('../../../../apps/account/src/assets/img/backgrounds/ft-pattern-pinstripe.png');
        background-size: 6px 6px;
        background-repeat: repeat;
    }
}

.translate-x-end-100 {
    transform: translateX(100%) !important;
}
