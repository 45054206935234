.ft2-checklist-container {
    border-radius: 4px;
    overflow: hidden;
    padding: 0.7em;
    width: 100%;
    //background: repeating-linear-gradient(
    //        135deg,
    //        rgba($gray-500, 0.1),
    //        rgba($gray-500, 0.1) 3px,
    //        transparent 3px,
    //        transparent 6px
    //);
    text-align: left;
}

.ft2-checklist-title {
    margin-bottom: 0.5em;
    font-weight: bold;
    line-height: 1;
}

.ft2-checklist {
    list-style: none;
    margin: 0;
    padding: 0;

    &_status {
        position: absolute;
        display: inline-block;
        border-radius: 50%;
        background: $gray-600;
        min-width: 1em;
        min-height: 1em;
        left: 0;
        top: 0.2em;

        &:after, &:before {
            content: '';
            height: 2px;
            display: block;
            position: absolute;
            background: $white;
        }

        &:after {
            width: 0.3em;
            transform: rotate(45deg);
            top: 55%;
            left: 20%;
        }

        &:before {
            width: 0.5em;
            transform: rotate(-45deg);
            top: 45%;
            left: 37%;
        }

        &.-valid {
            background: $primary-gradient;
        }
    }

    mat-icon {
        width: 16px;
        text-align: center;
    }

    li {
        display: flex;
        align-items: center;
        padding-left: 1.5em;
        position: relative;
        line-height: 1.3;
        margin-top: 0.4em;
    }
}

