@import "grid-variables";
@import "grid-breakpoints";
@import "grid-mixins";
@import "grid-framework";

// Row
//
// Rows contain and clear the floats of your columns.

.row{
    @include make-row();
}

// Remove the negative margin from default .row, then the horizontal padding
// from all immediate children columns (to prevent runaway style inheritance).
.no-gutters{
    margin-right: 0;
    margin-left: 0;

    > .col,
    > [class*="col-"]{
        padding-right: 0;
        padding-left: 0;
    }
}

// Columns
//
// Common styles for small and large grid columns

@include make-grid-columns();
@include make-grid();

