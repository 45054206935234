.ft2-edit-panel {
    display: flex;
    min-height: 80px;
    margin-bottom: 15px;
    padding: 20px;
    cursor: pointer;
    border: 1px solid $gray-200;
    border-radius: 2px;
    transition: 0.3s cubic-bezier(0, 0, 0.1, 1);
    position: relative;
    flex-wrap: wrap;
    line-height: 1.4;
    word-break: break-word;

    p {
        line-height: 1.4;
    }

    @include media(sm) {
        margin-bottom: 15px;
        padding: 15px;
    }

    &:hover {
        box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.2);
    }


    &.-disabled, &.-static, &.-read-only, &.-loading {
        cursor: default;

        &:hover {
            box-shadow: none;
        }
    }

    &.-read-only, &.-loading {
        * {
            opacity: 0.6;
        }
    }

    &.-read-only {
        cursor: not-allowed;
        pointer-events: none;
    }

    &.-loading:not(.-static) {
        cursor: progress;
        pointer-events: none;
        padding-right: 40px;
    }

    &.-disabled {
        background-color: $gray-100;
        cursor: default !important;

        .ft2-edit-dialog-trigger {
            cursor: default !important;
        }

        * {
            pointer-events: none !important;

        }
    }

    &.-static {
        justify-content: space-between;

        .ft2-edit-panel_spinner {
            display: none;
        }
    }

    &_label {
        width: calc(100% / 3);
        min-width: 120px;
        font-weight: 400;
        padding-right: 1em;

        &.-fluid {
            width: auto;
        }

        @include media(xs) {
            width: 100%;
        }

        & + p, & + div, & + .ft2-edit-panel_content {
            flex: 1;
        }
    }


    &_summary {
        opacity: 0.7;
        font-weight: 300;
        margin-top: 10px;
    }


    &_error {
        width: 100%;
        color: $danger-color;
        line-height: 1;
        text-align: right;
        flex: none !important;
        display: flex;
        justify-content: flex-end;
        margin-top: 5px;
    }

    > .col,
    > [class*="col-"] {
        padding-right: 0;
        padding-left: 0;
    }

    &_spinner {
        position: absolute;
        right: 10px;
        top: 20px;
        height: 1em;
        width: 1em;
    }

    .mat-slide-toggle.mat-disabled {
        opacity: 1 !important;

        .mat-slide-toggle-label {
            cursor: pointer !important;
        }
    }
}

.ft2-edit-panel-group {
    margin-bottom: 20px;

    .ft2-edit-panel {
        margin-bottom: -1px;
    }
}

.ft2-edit-dialog-trigger:has(.ft2-edit-panel.-read-only), .ft2-edit-dialog-trigger:has(.ft2-edit-panel.-disabled) {
    cursor: default !important;
}
