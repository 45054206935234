ngb-timepicker {
    fieldset {
        min-width: 0;
        padding: 0;
        margin: 0;
        border: 0;
    }

    .ngb-tp-input-container {
        button {
            padding: 8px 10px;
            color: $blue;
            cursor: pointer;
            border: none;
            background-color: transparent;
        }
    }

    .ngb-tp-chevron:before {
        content: "";
        display: inline-block;
        position: relative;
        border-style: solid;
        border-width: 2px 2px 0 0;
        width: .75em;
        height: .75em;
        top: .15em;
        left: .05em;
        transform: rotate(-45deg);
        vertical-align: middle;
    }

    .visually-hidden {
        display: none;
    }
}
