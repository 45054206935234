.border {
    border: 1px solid;
}

.border-top {
    border-top: 1px solid;
}

.border-left {
    border-left: 1px solid;
}

.border-right {
    border-right: 1px solid;
}

.border-bottom {
    border-bottom: 1px solid;
}

.border, .border-right, .border-bottom, .border-top, .border-left {

    &.-primary {
        border-color: $primary-color;
    }

    &.-accent {
        border-color: $accent-color;
    }

    &.-secondary {
        border-color: $secondary-color;
    }

    &.-gray {
        border-color: $gray-400;
    }
}

.border {
    &.-shadow:hover {
        box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.2);
    }
}