@import "../base/text-content";

// bootstrap variables
$zindex-tooltip: 9999;
$tooltip-margin: 20px;
$tooltip-opacity: 1;
$tooltip-arrow-height: 10px;
$tooltip-arrow-width: 20px;
$tooltip-arrow-color: black;
$tooltip-max-width: 300px;
$tooltip-padding: 15px 15px 20px 15px;
$tooltip-color: $gray-800;
$tooltip-bg: $white;
$tooltip-border-radius: 3px;

.tooltip {
    position: absolute;
    z-index: $zindex-tooltip;
    display: block;
    margin: $tooltip-margin;
    word-wrap: break-word;
    opacity: 0;
    padding: 0 1em;
    margin-bottom: -0.5em;
    border-radius: $tooltip-border-radius;
    font-family: $text-family;
    font-size: $medium-font-size;
    line-height: 1.4;
    text-transform: none;

    &.tooltip-small {
        .tooltip-inner {
            padding: 10px;
            font-size: $medium-font-size;
        }
    }

    &.show {
        opacity: $tooltip-opacity;
    }

    &.with-arrow {
        .tooltip-arrow {
            display: block;
            width: $tooltip-arrow-width;
            height: $tooltip-arrow-height;

            &:before {
                position: absolute;
                content: "";
                border-color: transparent;
                border-style: solid;
                animation: ftSlideUp $transition;
            }
        }
    }
}


.tooltip-inner {
    max-width: $tooltip-max-width;
    padding: $tooltip-padding;
    color: $tooltip-color;
    background-color: $tooltip-bg;
    border-radius: $tooltip-border-radius;
    animation: ftSlideUp $transition;
    box-shadow: $popup-shadow;
    text-align: left;
    pointer-events: none !important;
    @include text-content(14px);

    p {
        line-height: 1.4;

        &:last-child {
            margin-bottom: 0 !important;
        }
    }

    @include media(xs) {
        max-width: 240px;
    }
}

.bs-tooltip-top {
    padding: $tooltip-arrow-height 0;
    margin-bottom: -1em;

    .tooltip-arrow {
        bottom: 0;

        &:before {
            top: -1px;
            border-width: $tooltip-arrow-height calc($tooltip-arrow-width * .5) 0;
            border-top-color: $tooltip-bg !important;
        }
    }
}

.bs-tooltip-top-left {
    margin-left: 2em;
}

.bs-tooltip-right {
    padding: 0 $tooltip-arrow-height;
}

.bs-tooltip-bottom {
    padding: $tooltip-arrow-height 0;

    .tooltip-arrow {
        top: 0;

        &:before {
            bottom: -1px;
            border-width: 0 $tooltip-arrow-height calc($tooltip-arrow-width * .5);
            border-bottom-color: $tooltip-bg !important;
        }
    }
}

.bs-tooltip-left {
    padding: 0 $tooltip-arrow-height;
}

.bs-tooltip-auto {
    &[x-placement^="top"] {
        @extend .bs-tooltip-top;
    }

    &[x-placement^="right"] {
        @extend .bs-tooltip-right;
    }

    &[x-placement^="bottom"] {
        @extend .bs-tooltip-bottom;
    }

    &[x-placement^="left"] {
        @extend .bs-tooltip-left;
    }
}

