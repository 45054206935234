@import "../../../../libs/shared-assets/styles/globals";

.ft2-stories.ft2-detail-page {
    .ft2-detail-page {
        &-header {
            color: #222;
            background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.33) 0%, rgba(0, 0, 0, 0) 33%) !important;
            padding: 20px 15px 45px;
        }

        &-recommendation {
            min-height: 60px;

            &_button {
                opacity: 0.5;

                &:hover {
                    opacity: 1;
                }
            }
        }

        &_icon {
            background-color: #222;
            background-size: 16px;
            border-radius: 50%;
            width: 20px;
            height: 20px;

            mat-icon {
                width: 16px;
                height: 16px;
            }
        }

        &-photo {
            position: relative;
            height: 66vh;
            min-height: 400px;
            max-height: 75vw;
            margin: 0 auto;
            color: #fff;
            box-shadow: 0 4px 8px rgb(0 0 0 / 10%), 0 30px 40px -20px rgb(0 0 0 / 50%);
            overflow: hidden;
            width: 100vh;
            min-width: 600px;
    
            @include media(md){
                width: 100%;
                min-width: auto;
            }
    
            &.-quote {
                background-color: #20292f;
            }

            &_content {
                position: absolute;
                bottom: -1px;
                left: 0;
                width: 100%;
                padding: 20px;
                background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.66) 100%);
    
                &.-quote{
                    display: table-cell;
                    vertical-align: middle;
                    padding: 60px 30px 30px;
                    text-align: center;
    
                    .quote-text {
                        font-size: 32px;
                        overflow: auto;
                        height: 250px;
                    }
                }
            }

            .ft2-bg-image {
                background-color: #20292f;
        
                &.-qoute {
                    transform: scale(1.1) translateZ(0);
                    filter: blur(30px);
                    opacity: 0.3;
                }
            }
        }

        &_image {
            display: block;
            max-width: 320px;
            max-height: 320px;
            margin: 0 auto;
        }

        &-area {
            position: relative;
            background: #fff;
            padding: 90px 0;

            &.-story-info {
                z-index: 2;

                &::before {
                    content: "";
                    position: absolute;
                    top: -30px;
                    left: 50%;
                    transform: translateX(-50%);
                    opacity: 0.3;
                    z-index: -1;
                    width: 400px;
                    height: 2px;
                    margin-top: -2px;
                    background-color: #222;
                    animation: ft-anim-storymodal-section-hline 3s cubic-bezier(0.7, 0, 0.1, 1) both;
                }
        
                &::after {
                    content: "";
                    position: absolute;
                    top: -30px;
                    left: 50%;
                    transform: translateX(-50%);
                    z-index: -1;
                    width: 4px;
                    height: 500px;
                    background-image: linear-gradient(#222 0%, rgba(34, 34, 34, 0) 100%);
                    transform-origin: top center;
                    animation: ft-anim-storymodal-section-vline 3s cubic-bezier(0.7, 0, 0.1, 1) both;
                    opacity: 0.2;
                }
            }

            &.-description {
                padding: 15px 0;
            }

            &.-footer {
                background-color: #131619;
                position: relative;
                padding: 30px 15px;
                text-align: center;
                overflow-x: auto;
                overflow-y: hidden;
                white-space: nowrap;
                z-index: 1;
            }

            &_contexttabs {
                ul {
                    position: relative;
                    letter-spacing: 0.025em;
                    line-height: 1.2;
                    text-align: left;
                    padding: 0 0 5px 0;
    
                    &::before {
                        content: "";
                        display: block;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        border-bottom: 1px solid;
                        opacity: 0.2;
                    }
                }

                &.-toggle {
                    transition: opacity 0.3s cubic-bezier(0, 0, 0.1, 1);
                    margin-right: 20px;
                    display: inline-block;
                    vertical-align: middle;
    
                    span {
                        opacity: 0.5;
                        padding: 10px 0;
    
                        &:hover {
                            opacity: 1;
                        }
                    }
    
                    &.-active {
                        opacity: 1;
    
                        span {
                            opacity: 1;
                        }
                    }
    
                    &::before {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        border-bottom: 2px solid;
                        opacity: 0;
                        transform: scaleX(0.5);
                    }
    
                    &.-active::before {
                        content: "";
                        transition: 0.3s cubic-bezier(0.7, 0, 0.1, 1);
                        transition-property: opacity, transform;
                    }
                }
            }

            &_panel {
                display: inline-block;
                vertical-align: middle;
                width: 50%;
                min-width: 270px;
                max-width: 360px;
                height: 150px;
                margin: 0;
                color: #fff;
                background-color: #40494f;
                border: 0;
                white-space: normal;
                overflow: hidden;
                text-align: center;
                position: relative;
                min-height: 70px;
                margin-bottom: 20px;
                padding: 15px;
                line-height: 1.4;
                transition: 0.3s cubic-bezier(0, 0, 0.1, 1);
                transition-property: box-shadow;

                &-content {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 100%;
                    padding: 20px;
                    z-index: 1;

                    &:hover {
                        cursor: pointer;
                    }
                }

                &:hover {
                    box-shadow: 0 10px 10px -5px rgb(0 0 0 / 20%);
                    z-index: 1;

                    .ft2-bg-image {
                        transform: scale(1.1);
                    }
                }

                &.-broadcast {
                    .ft2-bg-image {
                        background-image: url($image-path + 'assets/img/backgrounds/ft-secondary-panel-broadcast.jpg');
                    }

                    .label {
                        letter-spacing: 0.6em;
                        margin-right: -0.6em;
                    }
                }

                &.-profile {
                    color: #222;
                    background: #fff;

                    .ft2-bg-image {
                        &::before {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            right: 0;
                            width: 100%;
                            height: 100%;
                            background-image: radial-gradient(50% 100% at 50% 50%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.66) 100%);
                        }

                        &.ft2-pic {
                            background: #f3f3f6 center center no-repeat;
                            background-size: cover;
                            border-radius: 0;
                            opacity: 0.2;
                            position: absolute;
                        }
                    }
                }

                &.-curation {
                    background: #20292f;
                }

                .ft2-bg-image {
                    transition: transform 0.5s cubic-bezier(0, 0, 0.1, 1);

                    &:hover {
                        cursor: pointer;
                        box-shadow: 0 10px 10px -5px rgb(0 0 0 / 20%);
                        z-index: 1;
                    }
                }
            }
        }
    }
}

.ft2-stories {
    &-library {
        margin-top: 110px;

        &_content {
            max-width: 1410px;
            margin: 0 auto;
        }
    }

    &-searchbar {
        width: 540px;

        &_button {
            top: 4px;
            width: 60px;
            height: 43px !important;
            border-radius: 0 4px 4px 0 !important;
            box-shadow: none !important;
        }
    }

    &-common-words {
        font-weight: 500;
        color: #90999F;
    
        li {
            display: inline-block;
            margin: 5px 2px;
        }
    
        &_tag {
            display: inline-block;
            padding: 5px 10px;
            color: #222;
            background-color: #e0e0e0;
            border-radius: 4px;
            opacity: 0.5;
            cursor: pointer;
    
            &:hover {
                opacity: 1;
                background-color: rgba(74, 144, 226, 0.3);
            }
        }
    
        ::ng-deep .mat-icon {
            &.material-icons {
                .icon-lg {
                    width: 13px !important;
                    height: 13px !important;
                }
            }
        }
    }

    &_sorting-select {
        border: 1px solid #c3c6c9;
        transition: all 0.3s cubic-bezier(0, 0, 0.1, 1);
        padding: 0 15px;
        border-radius: 4px;
        font-weight: 500;
        font-size: 16px;
        height: 32px;

        &:hover {
            border-color: #40494f;
            cursor: pointer;
        }
    }

    &-cards {
        flex-wrap: wrap;
        display: flex;
        margin: 0 -15px;

        &.-no-stories {
            padding: 100px 15px;
            color: #90999f;
            text-align: center;
            border: 1px solid #e0e0e0;
            width: 100%;
            height: auto;
        }

        .ft2-stories-card {
            min-height: 70px;
            flex: 0 0 33.333%;
            padding: 0 15px 45px;

            &_photo {
                height: 240px;
                width: 100%;
                border: 2px solid #000000;
                position: relative;
                padding: 15px;
                overflow: hidden;
            }

            &_photocaption {
                height: 80px;
                font-size: 12px;
                text-align: center;
                color: #fff;
                background: #000;
                position: relative;
                padding: 15px;
            }

            &_person-info {
                position: relative;
                min-height: 80px;
                padding: 15px;
                text-align: initial;
                color: #222;
                background-color: #fff;
            }

            &_quote {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                max-width: 22em;
                margin: 0 auto;
                padding: 15px;
                font-size: 18px;
                color: #fff;
                line-height: 1.4;
                text-align: center;
            }

            &_person-social {
                display: flex;
                margin-left: auto;
                align-items: center;
        
                mat-icon {
                    width: 18px;
                    height: 18px;
                    color: #9daccc;
                }
            }
        }

        .container {
            height: 100%;

            &.ft2-edit-panel {
                padding: 0;
                margin-bottom: 0;
            }
        }

        @include media(md){
            flex-direction: column;
        }
    }

    &-meta {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 10px;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3));
        z-index: 1;

        &_rating {
            float: right;
            margin-left: 10px;

            mat-icon {
                width: 36px;
                height: 16px;
            }
        }

        &_social-icons {
            float: left;
            margin-right: 10px;

            mat-icon {
                width: 16px;
                height: 16px;
            }
        }
    }

    &_alert {
        position: absolute;
        top: 0;
        width: 100%;
        margin: 0 -15px;
        padding: 2px;
        background: rgba(255, 255, 255, 0.66);
        color: #cb2027;
        font-size: 12px;
        font-weight: 700;
        text-align: center;
        z-index: 1;

        mat-icon {
            width: 16px;
            height: 16px;
        }
    }
}

.ft2-profile-image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: 0 2px 2px rgb(0 0 0 / 50%);
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.ft2-download-links {
    padding: 0;
    list-style: none;
    margin: 15px 0;
    white-space: nowrap;
    font-size: 0;
    position: relative;

    li {
        white-space: normal;
        font-size: 16px;
        float: left;
        width: 33%;
        padding: 5px;

        a {
            display: block;
            padding: 30px 5px;
            font-weight: 300;
            color: inherit;
            text-align: center;
            line-height: 1.2;
            border: 1px solid rgba(34, 34, 34, 0.3);
            white-space: nowrap;
            transition-property: border-color;

            &:hover {
                border-color: #222;
                cursor: pointer;
            }
        }
    }
}

.ft2-bg-image {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: 50% 25%;

    &.-quote {
        filter: blur(10px);
        transform: scale(1.2);

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: #000;
            opacity: 0.5;
        }
    }
}

.ft-icn-beta {
    top: -1em;
    margin: -5px 0;
    padding: 5px;
    border-radius: 2px;
    font-family: brandon-grotesque, sans-serif;
    font-size: 13px;
    font-weight: bold;
    font-style: normal;
    text-align: center;
    line-height: 1;
    text-transform: uppercase;
    text-indent: initial;
    color: #fff;
    background-color: #90999f;
}
.ft-icn-beta:before {
    content: "";
    position: absolute;
    bottom: -4px;
    left: 5px;
    border-style: solid;
    border-width: 4px 8px 0 0;
    border-color: #90999f transparent;
    display: block;
    z-index: 1;
}
.ft-icn-beta:after {
    content: "BETA";
    position: relative;
}
.faceBox {
    position: absolute;
    border: 1px solid #fff;
    -moz-box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.2), inset 0 1px 2px 1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.2), inset 0 1px 2px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.2), inset 0 1px 2px 1px rgba(0, 0, 0, 0.2);
}
.faceBox .faceNumber {
    position: absolute;
    top: 100%;
    left: 50%;
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    margin: auto;
    margin-top: 5px;
    font-size: 12px;
    font-weight: 700;
    padding: 2px 6px;
    background-color: rgba(0, 0, 0, 0.5);
    -ms-border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
}
.faceBox .faceNumber:after {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 0 2px 2px;
    border-color: rgba(0, 0, 0, 0.5) transparent;
    display: block;
    width: 0;
    z-index: 1;
    margin-left: -2px;
    top: -2px;
    left: 50%;
}
@media (max-width: 390px) {
    .faceBox {
        display: none;
    }
}
