@import "../globals";

.p-datatable {

    .p-datatable-thead > tr > th {
        font-weight: bold;
        text-transform: uppercase;
        font-family: $solid-family;
        color: #000;
        font-size: 13px;
        border-bottom: 1px solid $gray-200;
        background: transparent;
    }

    .p-datatable-tbody > tr > td {
        padding: 1.5rem 1rem;
    }

    .p-datatable-tfoot > tr > td {
        background: transparent;
    }

    .p-sortable-column.p-highlight {
        background: transparent;
        white-space: nowrap;

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

    .p-datatable-header {
        background: transparent;
        border-width: 0;
    }
}


.p-column-filter-overlay-menu {
    position: fixed !important;
    z-index: 3003 !important;
    display: block !important;
}

// Add default styles for non-menu overlay
.p-column-filter-overlay {
    .p-column-filter-constraint {
        padding: 1.25rem;
    }
    .p-column-filter-buttonbar {
        padding: 1.25rem;
    }
    .p-inputtext {
        display: initial;
    }
    .p-multiselect {
        max-width: 33vw;
    }
}


.ft2-report-parent-table {

    .ft-status-circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        display: inline-block;
        margin-right: 4px;
    }

    &_header {
        width: 20%;
        text-align: right !important;
        white-space: nowrap;

        &:first-child {
            width: 40%;
            text-align: left !important;
            padding-left: 0 !important;
        }
    }

    &_row {
        text-align: right !important;

        &:first-child {
            text-align: left !important;
            padding-left: 0 !important;
            cursor: pointer;
        }
    }

    &_toggle {
        display: flex;
        align-items: center;
    }

    &_footer {
        width: 20%;
        text-align: right !important;

        &:first-child {
            width: 60%;
            text-align: left !important;
            padding-left: 0 !important;
        }
    }
}

.ft2-report-nested-table {

    &_header {
        padding-left: 0 !important;
        width: 20%;
        text-align: right !important;
        white-space: nowrap;

        &:first-child {
            text-align: left !important;
        }
    }

    &_row {
        text-align: right !important;

        &:first-child {
            text-align: left !important;
            padding-left: 0 !important;
            white-space: nowrap;
            cursor: pointer;
        }
    }

    &_subrow {
        width: 20%;
        word-break: break-all;
        text-align: right !important;

        &:first-child {
            width: 40%;
        }
    }
}
