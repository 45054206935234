.ft2-nav-spacing {
    height: 80px;
}

.ft2-nav-backdrop {
    background: linear-gradient(
            to bottom,
            rgba($dark-color, 0.3) 0%,
            rgba($dark-color, 0) 20%,
            rgba($dark-color, 0) 100%
    );
}

.ft2-nav {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: flex-start;
    padding: 20px 15px;
    width: 100%;
    left: 0;
    top: 0;
    color: $white;
    font-size: $medium-font-size;
    z-index: 50;

    &.-sticky {
        position: fixed;
    }

    &.-text_dark {
        color: $base-color;

        &:before {
            display: none;
        }
    }

    &:before {
        content: '';
        z-index: -1;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 80px;
        background: linear-gradient(
                to bottom,
                rgba($dark-color, 0.5) 0%,
                rgba($dark-color, 0.3) 60%,
                rgba($dark-color, 0) 100%
        );
    }

    &.-more-gradient {
        &:after {
            content: "";
            z-index: -1;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 300px;
            background: linear-gradient(
                    to bottom,
                    rgba($dark-color, 0.3) 0,
                    rgba($dark-color, 0.1) 60%,
                    rgba($dark-color, 0) 100%
            );

            @include media(lg) {
                height: 200px;
            }

            @include media(sm) {
                height: 100px;
            }
        }
    }

    &_logo {
        position: absolute;
        top: 15px;
        left: 15px;
        display: block;
        width: 120px;
        height: 80px;

        &.-large {
            width: 150px;
        }

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}

// .ft2-flipto-badge {
//     display: block;
//     width: 40px;
//     height: 40px;
//     background: url($image-path + 'assets/img/ft2-badge-logo.png') no-repeat center;
//     background-size: cover;;
// }

.ft2-nav-menu {
    padding: 0;
    list-style: none;
    display: flex;
    margin: 0;
    position: relative;

    @include media(md) {
        display: none;
    }

    &_item {
        padding: 0 20px 20px 20px;
        position: relative;

        &.-align-sub {
            @include media(xl) {
                position: static;
            }
        }

        &.-has-alert {
            &:after {
                content: '';
                position: absolute;
                margin-left: 2px;
                width: 8px;
                height: 8px;
                background: $primary-color;
                border-radius: 50%;
            }
        }

        &:hover {
            .ft2-nav-menu-link:not(.-active) {
                opacity: 0.8;
            }

            .ft2-nav-sub {
                display: block;
            }

            .ft2-nav-sub_popup, .ft2-nav-sub_alerts {
                animation: ftSlideDown $transition;
            }
        }
    }
}

.ft2-nav-menu-link {
    @include solid-family(13px, 0.1em);
    opacity: 0.6;
    transition: opacity $transition;
    cursor: default;

    &.-active {
        opacity: 1;
    }
}

a.ft2-nav-menu-link {
    cursor: pointer;
}

.ft2-app-menu-entry {
    position: absolute;
    right: 15px;
    top: 20px;
    cursor: pointer;
    font-size: $large-font-size;
    padding: 0 15px;
}

.ft2-nav-sub {
    display: none;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    top: 100%;

    p {
        line-height: 1.4;
    }

    &_popup {
        background: $white;
        color: $base-color;
        overflow: hidden;
        border-radius: 4px;
        padding: 15px 0 0 0;
        box-shadow: 0 20px 20px -5px rgba(0, 0, 0, 0.3);
    }

    &_secondary-menu {
        display: flex;
        padding: 10px 10px 15px;

        li {
            border: 1px solid $gray-200;
            padding: 15px;
            flex: 1;
            margin: 0 2px;
            text-align: center;
            white-space: nowrap;
            font-weight: 500;
            font-size: 13px;
            cursor: pointer;
            transition: border-color $transition;

            &:hover {
                border-color: $gray-500;
            }
        }
    }

    &_primary-menu {
        display: flex;
        padding: 0;
        margin-bottom: 20px;
        cursor: pointer;

        &:hover li {
            opacity: 0.6;
        }

        li {
            width: 300px;
            flex-direction: column;
            padding: 20px;
            border-right: 1px solid $gray-200;
            transition: $transition;
            position: relative;

            .ft2-dialog-badge {
                position: absolute;
                left: 50%;
                margin-left: 30px;
            }

            @include media(lg) {
                width: 250px;
            }

            &:hover {
                opacity: 1;
            }

            &:last-child {
                border-right: none;
            }
        }
    }

    &_bottom-menu {
        background: $gray-100;
        border-top: 1px solid $gray-200;
        padding: 10px 15px 15px;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;

        li {
            margin: 5px 10px;
            opacity: 0.8;
            transition: $transition;
            white-space: nowrap;

            &:hover {
                opacity: 1;
            }

        }
    }

    &_vertical-divider {
        border-top: 1px solid $gray-200;
        padding-top: 20px;
    }

    &_vertical-menu {
        padding: 10px 10px 5px;
        min-width: 240px;
        text-align: center;
        @include solid-family();

        li {
            text-transform: uppercase;
            margin-bottom: 20px;

            a:hover {
                opacity: 0.7;
            }
        }
    }

    &_card-menu {
        padding: 5px 20px 10px 20px;
    }

    &_alerts {
        background: $gray-700;
        padding: 10px 15px;
        border-radius: 4px;
        color: $gray-200;
        box-shadow: 0 20px 20px -5px rgba(0, 0, 0, 0.3);
        margin-top: 10px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        max-width: 700px;

        li {
            padding: 5px 0;
            position: relative;
            display: flex;
            align-items: center;

            &:before {
                content: '';
                width: 10px;
                height: 10px;
                margin-right: 10px;
                background: $primary-color;
                border-radius: 50%;
            }
        }
    }
}

.ft2-card-menu {
    &_item {
        width: 270px;
        height: 120px;
        border-radius: 5px;
        background: #222428;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        color: $white;
        background-size: cover;
        cursor: pointer;
        position: relative;
        padding: 5px 15px;

        &:hover:before{
            background: rgba(0, 0, 0, 0);
        }

        &:before {
            content: '';
            @include overlay;
            background: rgba(0, 0, 0, 0.3);
            transition: $transition;
            z-index: 0;
        }

        > * {
            z-index: 1;
            position: relative;
        }
    }
}
