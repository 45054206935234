.ft2-screenshots {
    perspective: 1000px;
    padding-bottom: 15%;
    position: relative;
    display: flex;

    &.-left {
        justify-content: flex-end;

        .ft2-screenshot:last-child {
            right: auto;
            left: -10%;
        }
    }

    &.-right {
        justify-content: flex-start;

        .ft2-screenshot:last-child {
            left: auto;
            right: -10%;
        }
    }

    //@include media(sm) {
    //    position: relative;
    //
    //    &:after {
    //        content: '';
    //        position: absolute;
    //        height: 50%;
    //        width: 100%;
    //        left: 0;
    //        background: linear-gradient(to top, $white 30%, rgba($white, 0);
    //        bottom: 0;
    //        z-index: 1;
    //    }
    //}

    .ft2-screenshot {
        width: 90%;

        @include media(sm) {
            transform: none !important;
        }

        &:first-child {
            margin-top: 20%;
            position: relative;
            z-index: 1;
        }

        &:last-child {
            position: absolute;
            top: 0;
            left: -10%;
            transform-origin: 70%;
        }
    }
}

.ft2-screenshot, .ft2-screenshot-caption {
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 10px 20px 80px rgba(0,0,0, .2);

    @include media(md){
        transform: none !important;
        box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.2);
        width: auto !important;
        float: none !important;
    }

    &.-skew_right {
        transform: rotateX(5deg) rotateY(-22deg) rotate(2deg);
        transform-origin: 50%;
        width: 120%;
    }

    &.-skew_left {
        transform: rotateX(5deg) rotateY(22deg) rotate(-2deg);
        transform-origin: 50%;
        width: 120%;
        float: right;
    }
}

.ft2-screenshot-caption {
    background-color: transparent;
    box-shadow: none;
    padding: 20px 60px 30px 60px;
}
