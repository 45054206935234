.mat-icon {
    &.material-icons {
        font-size: inherit;
        height: auto;
        width: auto;

        &.icon-xs {
            font-size: 12px;
        }

        &.icon-sm {
            font-size: 16px;
        }

        &.icon-md {
            font-size: 18px;
        }

        &.icon-lg {
            font-size: 20px;
        }

        &.icon-xl {
            font-size: 36px;
        }

        &.icon-2xl {
            font-size: 48px;
        }

        &.icon-3xl {
            font-size: 60px;
        }
    }

    &:not(.material-icons) {
        font-size: 0;

        &.icon-xs {
            width: 12px;
            height: 12px;
        }

        &.icon-sm {
            width: 16px;
            height: 16px;
        }

        &.icon-md {
            width: 18px;
            height: 18px;
        }

        &.icon-lg {
            width: 20px;
            height: 20px;
        }

        &.icon-xl {
            width: 36px;
            height: 36px;
        }

        &.icon-2xl {
            width: 48px;
            height: 48px;
        }

        &.icon-3xl {
            width: 60px;
            height: 60px;
        }
    }
}

.ft2-stats-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70%);
    width: 100px !important;
    height: 100px !important;
    opacity: 0.2;
    background-size: contain;

    &::before, &::after {
        content: " ";
        position: absolute;
    }
}
