ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

*:focus {
    outline: none;
}


input:focus, select:focus, button:focus, textarea:focus {
    outline: none;
}

input, button, textarea, form {
    -webkit-appearance: none;
    -moz-appearance: none;
    //margin: 0;
}

a {
    color: inherit;
    font-weight: normal;
}

strong, b {
    font-weight: bold;
}

em {
    font-style: italic;
}

img {
    max-width: 100%;
}

figure {
    margin: 0;
}

h1, h2, h3, h4, h5, h6,
p,
figure,
blockquote {
    + h1, + h2, + h3, + h4, + h5, + h6,
    + p,
    + figure,
    + blockquote {
        margin-top: 0;
    }
}
