.ft2 {
    &.cdk-overlay-pane {
        min-height: 0;
    }

    @import "assets/styles/core/reset";
}


@import "ag-grid-community/dist/styles/ag-grid.css";
@import "ag-grid-community/dist/styles/ag-theme-material.css";
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "quill/dist/quill.snow.css";
@import 'swiper/swiper';
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";

@import "../../../libs/shared-assets/styles/shared-styles";


//.no-scroll {
//    overflow: hidden !important;
//}


//.ft2 {
//&.cdk-overlay-pane {
//    min-height: 0;
//}

//--------------------------------------/
// Core
//--------------------------------------/
//@import "assets/styles/core/reset";
//@import "assets/styles/core/media";
//@import "assets/styles/core/mixins";
//@import "assets/styles/core/functions";
//--------------------------------------/
// Base
//--------------------------------------/
//@import "assets/styles/base/variables";
//@import "assets/styles/base/colors";
//@import "assets/styles/base/fonts";
//@import "assets/styles/base/main";
//@import "assets/styles/base/animations";
//@import "assets/styles/base/containers";
//--------------------------------------/
// Grid
//--------------------------------------/
//@import "assets/styles/grid/grid";
//--------------------------------------/
// Helpers
//--------------------------------------/
//@import "assets/styles/helpers/alignment";
//@import "assets/styles/helpers/displays";
//@import "assets/styles/helpers/helpers";
//@import "assets/styles/helpers/customs";
//--------------------------------------/
// Elements
//--------------------------------------/
//@import "assets/styles/base/typography";
//@import "assets/styles/base/text-content";
//@import "assets/styles/elements/radio-switcher";
//@import "assets/styles/elements/button";
//@import "assets/styles/elements/input";
//@import "assets/styles/elements/touchpoint";
//@import "assets/styles/elements/snapshot";
//@import "assets/styles/elements/create-area";
//@import "assets/styles/elements/feature-card-view";
//@import "assets/styles/elements/feature-cards";
//@import "assets/styles/elements/table";
//@import "assets/styles/elements/journeys";
//@import "assets/styles/elements/browser";
//@import "assets/styles/elements/spaces";
//@import "assets/styles/elements/form";
//@import "assets/styles/elements/checkbox";
//@import "assets/styles/elements/toast";
//@import "assets/styles/elements/tabs";
//@import "assets/styles/elements/mark";
//@import "assets/styles/elements/widget";

//--------------------------------------/
// Customs
//--------------------------------------/
//@import "assets/styles/customs/ng-select";
//@import "assets/styles/customs/ag-grid";
//@import "assets/styles/customs/swiper";
//font-family: $text-family;

//[hidden] {
//    display: none !important;
//}
//}
//@import "assets/styles/helpers/spacing";
//@import "assets/styles/elements/header";
//@import "assets/styles/elements/badge";


/*--------------------------------------/
 Material
--------------------------------------*/
//@import "assets/styles/material/mat-dialog";
//@import "assets/styles/material/mat-tabs";
//@import "assets/styles/material/mat-icons";
//@import "assets/styles/material/mat-toggle";
//@import "assets/styles/elements/tooltip";
//@import "assets/styles/elements/editors";
//@import "assets/styles/customs/ngb-datepicker";

body .buorg {
    top: 20px;
    left: 0;
    right: 0;
    max-width: 640px;
    margin: 0 auto;
    border-radius: 4px;
    font: inherit;
    line-height: 1;
    color: #fff;
    border: 1px solid rgba(255,255,255,.3);
    background: rgba(1,6,61,.5);
    cursor: initial !important;
}

body .buorg-pad {
    line-height: 1.2;
}

body .buorg-buttons {
    margin-top: 10px;
}

body #buorgig, body #buorgul, body #buorgpermanent {
    padding: 5px 15px;
}

body #buorgul {
    color: #fff;
    background-color: transparent;
    background-image: linear-gradient(to bottom, #0f8275, #66AB75);
    box-shadow: 0 6px 12px -6px rgb(0 0 0 / 60%);
    background-size: 100% 100%;
    background-position: center bottom;
    border-radius: 4px;
}

body #buorgul:hover {
    text-decoration: none;
    background-size: 100% 250%;
}

body #buorgig {
    color: #F3F3F6;
    border: 1px solid rgba(255, 255, 255, 0.5);
    background: transparent;
    box-shadow: none;
    transition: all 0.3s cubic-bezier(0, 0, 0.1, 1);
    border-radius: 4px;
}

body #buorgig:hover {
    color: #fff;
    border-color: #fff;
}
