/*--------------------------------------/
 Path
--------------------------------------*/
@use "sass:math";
$image-path: '/dist/v2-hybrid/';

/*--------------------------------------/
 Base
--------------------------------------*/
$rup-z-index: 16500000;
$app-z-index: 16500001;
$container-width-narrower: 560px;
$container-width-narrow: 700px;
$container-width: 960px;
$container-width-wide: 1200px;
$container-width-wider: 1600px;

/*--------------------------------------/
 Colors
--------------------------------------*/

// Grays
$white: #fff !default;
$gray-100: #f3f3f6 !default;
$gray-200: #e0e0e0 !default;
$gray-300: #dedede !default;
$gray-400: #c3c6c9 !default;
$gray-500: #90999f !default;
$gray-600: #60696f !default;
$gray-700: #40494f !default;
$gray-800: #30393f !default;
$gray-900: #20292f !default;
$black: #000 !default;

// Colors
$red: #cb2027;
$red-light: #f5515f;
$green: #66AB75;
$green-dark: #0f8275;
$yellow: #f2ad18;
$blue: #486FD9;
$blue-dark: #213256;
$blue-darker: #17263b;
$violet: #3C3B96;
$orange: #ff8533;
$orange-dark: #d76f00;
$blackish: #131619;


//Theme
$base-color: #222;
$muted-color: $gray-500;
$primary-color: $green;
$accent-color: #D5498E;
$primary-dark-color: $green-dark;
$secondary-color: $blue;
$secondary-dark-color: $blue-dark;
$success-color: #66A720;
$warning-color: $orange;
$danger-color: $red;
$light-color: #F3F3F6;
$dark-color: #20292f;
$darker-color: #10191F;

//Social
$facebook-color: #3b5998;
$twitter-color: #55acee;
$linkedin-color: #287bbc;
$instagram-color: #515bd4;
$pintrest-color: #cb2027;
/*--------------------------------------/
 Gradients
--------------------------------------*/
//Theme
$primary-gradient: linear-gradient(to bottom, $primary-dark-color, $primary-color);
$secondary-gradient: linear-gradient(to bottom, $secondary-dark-color, $secondary-color);
$success-gradient: linear-gradient(to bottom, darken($success-color, 15), $success-color);
$warning-gradient: linear-gradient(to bottom, $orange-dark, $yellow);
$danger-gradient: linear-gradient(to bottom, darken($danger-color, 15), $danger-color);
$light-gradient: linear-gradient(to bottom, $gray-200, $white);
$dark-gradient: linear-gradient(to bottom, $dark-color, $gray-600);
$blue-dark-gradient: linear-gradient(to bottom, $blue-dark, $darker-color);
$blue-darker-gradient: linear-gradient(to bottom, $blue-darker, $darker-color);
$pink-blue-gradient: linear-gradient(to right, $accent-color, $blue);
$green-blue-gradient: linear-gradient(to right, $green, $blue);
$striped-gradient: linear-gradient(135deg, $white 28.57%, $gray-200 28.57%, $gray-200 50%, $white 50%, $white 78.57%, $gray-200 78.57%, $gray-200 100%);
$striped-light-gradient: linear-gradient(135deg, $white 28.57%, $gray-100 28.57%, $gray-100 50%, $white 50%, $white 78.57%, $gray-100 78.57%, $gray-100 100%);

//Social
$facebook-gradient: linear-gradient(to bottom, darken($facebook-color, 15), $facebook-color);
$twitter-gradient: linear-gradient(to bottom, darken($twitter-color, 15), $twitter-color);
$linkedin-gradient: linear-gradient(to bottom, darken($linkedin-color, 15), $linkedin-color);
$instagram-gradient: linear-gradient(45deg, #f09433 0%, #e6683c 15%, #dc2743 25%, #cc2366 40%, #bc1888 65%, #515bd4 100%);
$pintrest-gradient: linear-gradient(to bottom, darken($pintrest-color, 15), $pintrest-color);


/*--------------------------------------/
 Font size
--------------------------------------*/
$tiny-font-size: 10px;
$small-font-size: 12px;
$medium-font-size: 14px;
$base-font-size: 16px;
$large-font-size: 18px;
$huge-font-size: 24px;
$button-font-size: 16px;
$line-height: 1.6;

/*--------------------------------------/
 Fonts
--------------------------------------*/
$text-family: museo-sans, sans-serif;
$solid-family: brandon-grotesque, "Helvetica Neue", Helvetica, Arial, sans-serif;
$bold-family: museo-sans, sans-serif;
$icon-family: 'Material Icons';


/*--------------------------------------/
 Shadows
--------------------------------------*/
$card-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 30px 100px -40px rgba(0, 0, 0, 0.5);
$popup-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.15);
$img-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.15);


/*--------------------------------------/
Animation
--------------------------------------*/
$transition: .3s cubic-bezier(0, 0, 0.1, 1); // standard settings for most interactions

$transitionDuration: .3s;
$transitionEaseOut: cubic-bezier(0, 0, 0.1, 1);
$transitionEaseIn: cubic-bezier(0.7, 0, 0, 0);
$transitionEaseInOut: cubic-bezier(0.7, 0, 0.1, 1);

/*--------------------------------------/
 Grid
--------------------------------------*/
$grid-columns: 12 !default;
$grid-gutter-width-base: 30px !default;
$grid-breakpoints: (
    wide: 0px,
    xxl: 1440px,
    xl: 1200px,
    lg: 960px,
    md: 768px,
    sm: 640px,
    xs: 480px
) !default;

$grid-gutter-widths: (
    wide: $grid-gutter-width-base,
    xxl: $grid-gutter-width-base,
    xl: $grid-gutter-width-base,
    lg: $grid-gutter-width-base,
    md: math.div($grid-gutter-width-base, 1.5),
    sm: math.div($grid-gutter-width-base, 1.5),
    xs: math.div($grid-gutter-width-base, 1.5)
) !default;

/*--------------------------------------/
 Spacings
--------------------------------------*/
// margin and padding values
$space-values: (
    auto: 'auto',
    0: '0px',
    1: '5px',
    2: '10px',
    3: '15px',
    4: '20px',
    5: '30px',
    6: '45px',
    7: '60px',
    8: '75px',
    9: '90px',
    10: '100px',
    11: '110px',
    12: '120px',
    13: '130px',
    14: '150px',
    15: '180px',
    16: '240px'
) !default;

/*--------------------------------------/
 Typography
--------------------------------------*/


// Headers

$headings: (
    h1: 48, // 3rem
    h2: 42, // 2.625rem
    h3: 36, // 2.25rem
    h4: 32, // 2rem
    h5: 24, // 1.5rem
    h6: 18, // 1.125rem
);

$responsive-headings: (
    h1: (
        xl: 42,
        sm: 36
    ),
    h2: (
        xl: 36,
        sm: 32
    ),
    h3: (
        xl: 32,
        sm: 28
    ),
    h4: (
        xl: 28,
        sm: 24
    ),
    h5: (
        sm: 22
    ),
    h6: (
        sm: 16
    )
);


// Display headers

$display-headers: (
    display-1: 96, // 6rem
    display-2: 72, // 4.5rem
    display-3: 64, // 4rem
    display-4: 56 // 3.5rem
);

$responsive-displays-headers: (
    display-1: (
        xl: 80,
        sm: 64
    ),
    display-2: (
        xl: 64,
        sm: 56
    ),
    display-3: (
        xl: 56,
        sm: 48
    ),
    display-4: (
        xl: 48,
        sm: 40
    )
);
