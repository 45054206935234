table.ft2-table {
    line-height: 1.2;
    
    &.-striped:not(.ft2-table-summary) {
        td {
            padding: 12px 0;
            border-top: 1px solid $gray-200;
        }
    }

    &.-fixed-height {
        tbody {
            display: block;
            overflow: auto;
            border-bottom: 1px solid $gray-700;

            &::-webkit-scrollbar-track {
                border-radius: 10px;
                background-color: rgba($gray-100, 0.5);
            }

            &::-webkit-scrollbar {
                width: 6px;
                background-color: transparent;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background-color: $gray-300;
            }
        }

        tr {
            display: table;
            width: 100%;
            table-layout: fixed;
        }
    }

    th {
        @extend .solid-text;
        font-size: 13px;
        vertical-align: top;
    }


    tbody:before {
        display: none !important;
    }

    td {
        border-bottom: none;
        padding: 5px 0;
    }

    .ft2-table-summary {
        td {
            padding-top: 10px;
            font-weight: 500;
            border-top: 1px solid $gray-400;
        }
    }

    &.-large {
        thead {
            font-size: $base-font-size;
            border-bottom: 1px solid $gray-700;

            th {
                padding-bottom: 20px;
            }
        }

        tbody {
            font-size: $large-font-size;

            tr {
                border-bottom: 1px solid $gray-200;
            }

            td {
                padding: 20px 15px;
            }
        }

        .ft2-table-summary {
            padding-top: 40px;
            padding-bottom: 20px;
            border-top: 1px solid $gray-700;

            tr {
                border: none;
            }

            td {
                padding-top: 0;
                padding-bottom: 15px;
                font-weight: bold;
                border: none;
            }
        }
    }
}
