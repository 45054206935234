/*--------------------------------------/
 Core
--------------------------------------*/
$yiq-contrasted-threshold: 200 !default;

/*--------------------------------------/
 Grays
--------------------------------------*/
$grays: () !default;
$grays: map-merge(
        (
            "gray-100": $gray-100,
            "gray-200": $gray-200,
            "gray-300": $gray-300,
            "gray-400": $gray-400,
            "gray-500": $gray-500,
            "gray-600": $gray-600,
            "gray-700": $gray-700,
            "gray-800": $gray-800,
            "gray-900": $gray-900
        ),
        $grays
);
/*--------------------------------------/
 Colors
--------------------------------------*/
$colors: () !default;
$colors: map-merge(
        (
            'red': $red,
            'green': $green,
            'green-dark': $green-dark,
            'yellow': $yellow,
            'blue': $blue,
            'blue-dark': $blue-dark,
            'orange': $orange,
            'orange-dark': $orange-dark
        ),
        $colors
);
/*--------------------------------------/
 Theme
--------------------------------------*/
$theme-colors: () !default;
$theme-colors: map-merge(
        (
            'white': $white,
            'muted': $muted-color,
            'primary': $primary-color,
            'primary-dark': $primary-dark-color,
            'secondary': $secondary-color,
            'accent': $accent-color,
            'secondary-dark': $secondary-dark-color,
            'success': $success-color,
            'warning': $warning-color,
            'danger': $danger-color,
            'light': $light-color,
            'dark': $dark-color,
            'darker': $darker-color,
            'black': $black,
            'blackish': $blackish,
            'blue-dark': $blue-dark,
            'blue-darker': $blue-darker,
        ),
        $theme-colors
);

/*--------------------------------------/
 Gradients
--------------------------------------*/
$theme-gradients: () !default;
$theme-gradients: map-merge(
        (
            'primary': $primary-gradient,
            'secondary': $secondary-gradient,
            'success': $success-gradient,
            'warning': $warning-gradient,
            'danger': $danger-gradient,
            'light': $light-gradient,
            'dark': $dark-gradient,
            'blue-dark': $blue-dark-gradient,
            'blue-darker': $blue-darker-gradient,
            'pink-blue': $pink-blue-gradient,
            'green-blue': $green-blue-gradient
        ),
        $theme-gradients
);
/*--------------------------------------/
 Social gradients
--------------------------------------*/
$social-gradients: () !default;
$social-gradients: map-merge(
        (
            'facebook': $facebook-gradient,
            'twitter': $twitter-gradient,
            'linkedin': $linkedin-gradient,
            'instagram': $instagram-gradient,
            'pintrest': $pintrest-gradient,
        ),
        $social-gradients
);
/*--------------------------------------/
 Elements
--------------------------------------*/

@mixin create-bg($selector, $color) {
    #{$selector} {
        background-color: $color !important;
        color: color-yiq($color);

        &:before {
            background-color: $color;
        }
    }
}

@mixin create-text-color($selector, $color) {
    #{$selector} {
        color: $color !important;
    }
}


@mixin create-bg-gradient($selector, $color, $text-color) {
    #{$selector} {
        background-image: $color !important;
        color: $text-color;

        &:after, &:before {
            background-image: $color !important;
        }
    }
}







