.ft2-tree-droppable {
    background: $gray-100;
    border-radius: 4px;
    border: 1px dashed $gray-200;
    padding: 5px 10px;
    overflow: hidden;
    min-height: 62px;


    .cdk-drag-placeholder {
        opacity: 0;
    }

    // .ft2-tree-node {
    //     &:before, &:after {
    //         opacity: 0 !important;
    //     }
    // }
}

// .cdk-drop-list-dragging, .cdk-drag-dragging {
//     .ft2-tree-node {
//         &:before, &:after {
//             opacity: 0 !important;
//         }
//     }
// }

.cdk-drag-preview {
    &:after {
        opacity: 0 !important;
    }

    .ft2-tree-node {
        box-shadow: $popup-shadow;

        // &:before, &:after {
        //     opacity: 0 !important;
        // }
    }

    .ft2-tree-node_children, .ft2-tree-node_details {
        display: none;
    }
}

.cdk-drag-placeholder {
    background: $gray-100;
    border-radius: 4px;
    border: 1px dashed $gray-200;
    max-height: 62px;

    > * {
        opacity: 0;
    }
}

ft-tree-node {
    display: block;
    position: relative;
    margin-bottom: 20px;
    width: 100%;

    &:last-of-type {
        margin-bottom: 0;

        // .ft2-tree-node:after {
        //     height: 42px;
        // }
    }
}

.ft2-tree-node {
    position: relative;
    padding: 0 15px;
    font-size: $base-font-size;
    border: 1px solid $gray-200;
    border-radius: 4px;
    background: $white;
    cursor: pointer;

    // &:before {
    //     content: '';
    //     position: absolute;
    //     top: 30px;
    //     left: -15px;
    //     width: 15px;
    //     height: 2px;
    //     background: $gray-300;
    // }

    // &:after {
    //     content: '';
    //     position: absolute;
    //     top: -10px;
    //     left: -15px;
    //     height: calc(100% + 15px);
    //     width: 2px;
    //     background: $gray-300;
    // }

    &_title {
        padding: 20px 0;
        display: flex;
        align-items: center;
        font-weight: 500;
    }

    &_details {
        overflow: hidden;
        padding-left: 30px;

        > *:last-child {
            padding-bottom: 20px;
            display: block;
        }
    }

    &_children {
        overflow: hidden;
        position: relative;
        padding-left: 30px;
    }

    &_remove-btn {
        position: absolute;
        right: 20px;
        top: 20px;
        color: $muted-color;
        font-size: $medium-font-size;
    }
}
