/*--------------------------------------/
 Settings
--------------------------------------*/
$select: (
    height: 44px,
    padding: 15px,
    border-radius: 4px,
    font-size: $base-font-size,
    font-weight: 300,
    border: 1px solid $gray-200,
    line-height: 1.4,
    width: 100%,
    focus-color: $gray-400,
    dark-background-color: $gray-900
);

select.ft2-select {
    color: $base-color;
    font-weight: map-get($select, 'font-weight');
    border: map-get($select, 'border');
    font-size: map-get($select, 'font-size');
    max-width: 100%;
    padding: 0 map-get($select, 'padding');
    height: map-get($select, 'height');
    font-family: $text-family;
    border-radius: map-get($select, 'border-radius');
    width: map-get($select, 'width');
    line-height: map-get($select, 'line-height');
    background-color: map-get($select, 'background-color');
    transition: border-color $transition;
    -webkit-appearance: menulist;
    -moz-appearance: menulist;

    &:focus {
        border-color: map-get($select, 'focus-color');
    }

    &:disabled, &.-disabled {
        background-color: rgba($gray-400, .3);
        cursor: default;
        color: $gray-500 !important;
        -webkit-text-fill-color: inherit;
    }

    &.-dark {
        background-color: map-get($select, 'dark-background-color');
        border-color: $darker-color;
        color: $light-color;
    }

    &.-pure {
        border: none;
        background: none;
    }

    &.-no-border .ng-select-container {
        border: none !important;
        border-bottom: none !important;
    }

    &.-no-border {
        border: none;
    }

    &.-large {
        height: map-get($input, 'height') + 10px;
    }

    &.-medium {
        height: map-get($input, 'height');
    }

    &.-small {
        height: map-get($input, 'height') - 10px;
        font-size: map-get($input, 'font-size') - 2px;
    }

    &.-width_auto {
        width: auto;
    }

    &.-wide {
        width: 100%;
    }

    &.-round {
        border-radius: 10em;
        padding-left: map-get($input, 'padding');
        padding-right: map-get($input, 'padding');
    }

    &.-radius {
        border-radius: map-get($input, 'border-radius') * 2;
    }

    &.-square {
        border-radius: 0;
    }

    &.-center {
        text-align: center;
    }
}
