.ng-select.ft2-select {
    /*--------------------------------------/
     Container
    --------------------------------------*/

    &.ng-select-opened > .ng-select-container {
        z-index: 1051;
    }

    &.ng-select-focused > .ng-select-container {
        border-color: map-get($select, 'focus-color') !important;
        box-shadow: none !important;
    }

    .ng-select-container {
        color: $base-color;
        font-weight: map-get($select, 'font-weight');
        border: map-get($select, 'border');
        font-size: map-get($select, 'font-size');
        max-width: 100%;
        min-height: map-get($select, 'height');
        font-family: $text-family;
        border-radius: map-get($select, 'border-radius');
        width: map-get($select, 'width');
        line-height: map-get($select, 'line-height');
        transition: border-color $transition;

        &:hover {
            box-shadow: none;
        }
    }

    /*--------------------------------------/
     Value
    --------------------------------------*/

    .ng-value-container {
        padding: 0 map-get($select, 'padding');
        display: flex;
        align-items: center;

        .ng-input {
            height: 100%;
            top: 0 !important;
            padding-left: map-get($select, 'padding') !important;

            input {
                height: 100%;
                font-family: $text-family !important;
                font-size: map-get($select, 'font-size') !important;
                font-weight: map-get($select, 'font-weight') !important;
                padding: 0;
            }
        }
    }

    /*--------------------------------------/
     Multiple value
    --------------------------------------*/

    &.ng-select-multiple .ng-select-container .ng-value-container {
        padding: 5px map-get($select, 'padding') - 3px;


        .ng-input, .ng-placeholder {
            padding: 0 3px !important;
            top: auto;
        }

        .ng-value {
            background: $primary-gradient;
            border-radius: 2px;
            display: flex;
            color: $white;
            font-size: $medium-font-size;
            margin: 3px;
        }

        .ng-value-label {
            order: 0;
            border-right: 1px solid rgba($white, 0.2) !important;
            padding: 3px 7px;
        }

        .ng-value-icon {
            background: none;
            opacity: 0.7;
            transition: opacity $transition;
            order: 1;
            border: none !important;
            display: inline-flex;
            align-items: center;
            padding: 1px 6px;

            &:hover {
                background: none;
                opacity: 1;
            }
        }
    }

    /*--------------------------------------/
     Clear button
    --------------------------------------*/

    .ng-clear-wrapper:hover .ng-clear {
        color: $base-color;
    }

    /*--------------------------------------/
     Dropdown
    --------------------------------------*/

    .ng-dropdown-panel {
        animation: ftSlideDown $transition;
        border: map-get($select, 'border');
        text-align: left;


        .ng-option {
            &.ng-option-marked {
                background: rgba($base-color, 0.1) !important;
            }

            &.ng-option-selected {
                background: rgba($primary-color, 0.2) !important;
                color: $primary-dark-color !important;

                .ng-option-label {
                    font-weight: normal;
                }
            }

            &.ng-option-disabled {
                color: $base-color !important;
                opacity: .5;
            }
        }
    }

    /*--------------------------------------/
     Modifiers
    --------------------------------------*/
    &.-large {
        .ng-select-container {
            min-height: map-get($select, 'height') + 10px;
            font-size: $base-font-size;
        }

        .ng-option {
            padding: 15px;
        }
    }

    &.-no-border .ng-select-container {
        border: none;
    }

    &.-medium .ng-select-container {
        min-height: map-get($select, 'height');
    }

    &.-small .ng-select-container {
        min-height: map-get($select, 'height') - 10px;
    }

    &.-round {
        &.ng-select-opened.ng-select-bottom .ng-select-container {
            transition: all $transition;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        &.ng-select-opened.ng-select-top .ng-select-container {
            transition: all $transition;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }

        .ng-select-container {
            border-radius: map-get($select, 'height') * 0.5;
            padding-left: map-get($select, 'padding');
            padding-right: map-get($select, 'padding');
        }

        &.-large .ng-select-container {
            border-radius: (map-get($select, 'height') + 10px) * 0.5;
        }

        &.-small .ng-select-container {
            border-radius: (map-get($select, 'height') - 10px) * 0.5;
        }
    }

    &.-radius .ng-select-container {
        border-radius: map-get($select, 'border-radius') * 2;
    }

    &.-square .ng-select-container {
        border-radius: 0;
    }

    &.-left {
        text-align: left;

        .ng-dropdown-panel {
            text-align: left;
        }

        .ng-value-container {
            justify-content: flex-start;
        }
    }

    &.-center {
        text-align: center;

        .ng-dropdown-panel {
            text-align: center;
        }

        .ng-value-container {
            justify-content: center;
        }
    }

    &.-right {
        text-align: right;

        .ng-dropdown-panel {
            text-align: right;
        }

        .ng-value-container {
            justify-content: flex-end;
        }
    }

    &.-pure {
        &.ng-select-focused > .ng-select-container {
            border-color: map-get($select, 'dark-background-color') !important;
        }

        .ng-select-container {
            border: none;
            background: none;
            border-radius: 0;
            border-bottom: 2px solid rgba(map-get($select, 'dark-background-color'), 0.7);

            .ng-value-container {
                padding-left: 0;
                padding-right: 0;
            }

            .ng-arrow-wrapper {
                padding-right: 0;
                width: 20px;
            }
        }
    }

    &.-no-border {
        .ng-select-container {
            border-bottom: none !important;
        }
    }

    &.-dark {
        .ng-select-container {
            background-color: map-get($select, 'dark-background-color');
            border-color: map-get($select, 'dark-background-color');
            color: $light-color;

        }

        &.ng-select-focused > .ng-select-container {
            border-color: map-get($select, 'dark-background-color') !important;
        }

        .ng-dropdown-panel {
            border-color: map-get($select, 'dark-background-color');

            .ng-dropdown-panel-items {
                background-color: map-get($select, 'dark-background-color');
                border-top: 1px solid rgba($light-color, 0.05);


                .ng-option {
                    background-color: map-get($select, 'dark-background-color');
                    color: $light-color;

                    &.ng-option-marked {
                        background: rgba($light-color, 0.05) !important;
                    }

                    &.ng-option-selected {
                        background: rgba($primary-color, 0.2) !important;
                        color: $primary-color !important;
                    }
                }
            }
        }
    }

    &.-solid {
        text-transform: uppercase;
        font-family: $solid-family;

        .ng-value-container {
            font-family: $solid-family;
        }
    }

    &.-solid-large {
        .ng-value-container {
            text-transform: uppercase;
            font-family: $solid-family;
            font-size: $large-font-size;
        }
    }

    &.-medium-font {
        .ng-value-container {
            font-family: $text-family;
            font-size: $medium-font-size;
        }
    }

    &.-no-border {
        .ng-select-container {
            border-bottom: none;
        }
    }

    &.-no-arrow {
        .ng-arrow-wrapper {
            display: none;
        }
    }
}

.ng-dropdown-panel {
    width: auto !important;
    min-width: 100%;
}
