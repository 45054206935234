.ft-daterange-picker-calendar {
    background: $white;

    &.-dark {
        background: $dark-color;
        color: $white;

        ngb-datepicker-navigation-select > .custom-select {
            color: $white;

            option {
                color: $gray-800;
            }
        }

        .ngb-dp-month-name {
            color: $gray-500;
        }

        .ngb-dp-weekday, .custom-day {
            color: $gray-400;
        }

        .custom-day.disabled {
            &.faded, &:hover {
                color: $gray-400 !important;
            }
        }
    }

    .ngb-dp-arrow-btn:focus {
        outline: none;
    }

    .ngb-dp-header {
        padding-top: 15px;
        border-radius: 0;
        background: transparent;
    }

    ngb-datepicker-navigation {
        padding: 0 10px;
    }

    .ngb-dp-navigation-chevron {
        border-width: 2px 2px 0 0;
    }

    .ngb-dp-navigation-chevron {
        color: $secondary-color;

        &:hover {
            color: $secondary-dark-color;
        }
    }

    .ngb-dp-month:first-child .ngb-dp-week {
        padding-left: 0;
    }

    ngb-datepicker {
        border: none;
        border-radius: 0;
    }

    .ngb-dp-arrow {
        height: 25px;
    }

    .ngb-dp-months {
        padding: 0 5px;
    }

    .ngb-dp-month {
        margin: 0 10px;
    }

    .ngb-dp-month + .ngb-dp-month .ngb-dp-month-name, .ngb-dp-month + .ngb-dp-month .ngb-dp-week {
        padding-left: .25rem;
    }

    .ngb-dp-month-name {
        font-family: $solid-family;
        font-size: $medium-font-size;
        color: $base-color;
        background: transparent;
        height: 24px;
    }

    .ngb-dp-weekday {
        font-family: $solid-family;
        font-size: $small-font-size;
        color: $muted-color;
        font-style: normal;
    }

    ngb-datepicker-navigation-select > .custom-select {
        border: none;
        border-bottom: 1px solid $gray-500;
        background: none;
        margin: 0 5px;
        padding: 0;
        height: 22px;
    }

    .ngb-dp-day.hidden {
        display: block !important;
    }

    .ngb-dp-day:focus {
        outline: none !important;
    }

    .ngb-dp-day, .ngb-dp-week-number, .ngb-dp-weekday {
        width: 32px;
        height: 32px;
    }

    .custom-day {
        text-align: center;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $text-family;
        font-size: $medium-font-size;
        color: $base-color;
    }

    .custom-day.focused {
        background-color: transparent;
    }

    .custom-day.range, .custom-day:not(.disabled):hover {
        background-color: $secondary-color;
        color: $white;
    }


    .custom-day.faded {
        background-color: rgba($secondary-color, 0.5);
    }

    .custom-day.disabled {
        opacity: 0.5;
        cursor: default;

        &.faded, &:hover, &.focused, &:focus {
            background: none !important;
            color: inherit !important;
        }
    }
}
