@keyframes ftFadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes ftFadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes ftSlideUpByMargin {
    0% {
        margin-top: var(--ft-slide-up-shift, 30px);
        opacity: 0;
    }

    100% {
        margin-top: 0;
        opacity: 1;
    }
}

@keyframes ftSlideUp {
    0% {
        transform: translateY(var(--ft-slide-up-shift, 30px));
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes ftSlideDown {
    0% {
        transform: translateY(-30px) translateZ(0);
        opacity: 0;
    }

    100% {
        transform: translateY(0) translateZ(0);
        opacity: 1;
    }
}

@keyframes ftSlideLeft {
    0% {
        transform: translateX(10%) translateZ(0);
        opacity: 0;
    }

    100% {
        transform: translateX(0) translateZ(0);
        opacity: 1;
    }
}

@keyframes ftSlideRight {
    0% {
        transform: translateX(-10%) translateZ(0);
        opacity: 0;
    }

    100% {
        transform: translateX(0) translateZ(0);
        opacity: 1;
    }
}

@keyframes ftScaleUp {
    0% {
        transform: scale(0.9) translateZ(0);
        opacity: 0;
    }

    100% {
        transform: scale(1) translateZ(0);
        opacity: 1;
    }
}

@keyframes ftScaleDown {
    0% {
        transform: scale(1.5) translateZ(0);
        opacity: 0;
    }

    100% {
        transform: scale(1) translateZ(0);
        opacity: 1;
    }
}

@keyframes ftFlipUp {
    0% {
        transform: rotateX(90deg) translateZ(0);
        opacity: 0;
    }

    100% {
        transform: rotateX(0) translateZ(0);
        opacity: 1;
    }
}

@keyframes ftFlipDown {
    0% {
        transform: rotateX(90deg) translateZ(0);
        opacity: 0;
    }

    100% {
        transform: rotateX(0) translateZ(0);
        opacity: 1;
    }
}

.ft-slide-up-by-margin {
    animation: ftSlideUpByMargin .6s cubic-bezier(0, 0, 0.1, 1) both;
}

.ft-slide-up {
    animation: ftSlideUp .6s cubic-bezier(0, 0, 0.1, 1) both;
}

.ft-slide-down {
    animation: ftSlideDown .3s cubic-bezier(0, 0, 0.1, 1) both;
}

.ft-fade-in {
    animation: ftFadeIn .6s cubic-bezier(0, 0, 0.1, 1) both;
}

.ft-fade-out {
    animation: ftFadeOut .3s cubic-bezier(.7, 0, 0, 0) both;
}
