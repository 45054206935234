$radio: (
    container-size: 20px,
    mark-size: 12px,
    label-margin: 15px,
    border-color: $gray-500,
    border-width: 2px,
    background-color: transparent,
    mark-color: $primary-color
);


.ft2-radio:checked,
.ft2-radio:not(:checked) {
    position: absolute;
    left: -9999px;
}

.ft2-radio:checked + label,
.ft2-radio:not(:checked) + label {
    position: relative;
    padding-left: map-get($radio, 'container-size') + map-get($radio, 'label-margin');
    cursor: pointer;
    display: inline-flex;
    align-items: center;
}

.ft2-radio:checked + label:before,
.ft2-radio:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 2px;
    margin-top: map-get($radio, 'container-size') * 0.5;
    width: map-get($radio, 'container-size');
    height: map-get($radio, 'container-size');
    border: map-get($radio, 'border-width') solid map-get($radio, 'border-color');
    border-radius: 100%;
    background: map-get($radio, 'background-color');
}

.ft2-radio:checked + label:after,
.ft2-radio:not(:checked) + label:after {
    content: '';
    width: map-get($radio, 'mark-size');
    height: map-get($radio, 'mark-size');
    background: map-get($radio, 'mark-color');
    position: absolute;
    top: 0;
    margin-top: (map-get($radio, 'container-size') * 0.5) + (map-get($radio, 'mark-size') * 0.5);
    left: (map-get($radio, 'container-size') - map-get($radio, 'mark-size')) * 0.5;
    border-radius: 100%;
    transition: $transition;
}

.ft2-radio:not(:checked) + label:after {
    opacity: 0;
    transform: scale(2);
}

.ft2-radio:checked + label:after {
    opacity: 1;
    transform: scale(1);
}
