/*--------------------------------------/
 Text
--------------------------------------*/

p {
    line-height: $line-height;

    i, em {
        font-style: italic;
    }

    b {
        font-weight: 500 !important;
    }

    strong {
        font-weight: 700 !important;
    }

    s {
        text-decoration: line-through;
    }

    &.medium-text, &.small-text{
        line-height: 1.4;
    }
}

.uppercase {
    text-transform: uppercase;
}

.lowercase {
    text-transform: lowercase !important;
}

.underline {
    text-decoration: underline;
}

.strike-through {
    text-decoration: line-through;
}

/*--------------------------------------/
 Weights
--------------------------------------*/

.bold-text, b {
    font-weight: 500 !important;
}

.bolder-text {
    font-weight: 600 !important;
}

.strong-text, strong {
    font-weight: 700 !important;
}

/*--------------------------------------/
 Sizes
--------------------------------------*/

.tiny-text {
    font-size: $tiny-font-size;
}

.small-text, small {
    font-size: $small-font-size;
}

.medium-text {
    font-size: $medium-font-size;
}

.base-text {
    font-size: $base-font-size;
}

.large-text {
    font-size: $large-font-size;
}

.huge-text {
    font-size: $huge-font-size;
}

.skewd-text {
    &.-left {
        transform: rotateX(5deg) rotateY(22deg) rotate(-2deg);
    }

    &.-right {
        transform: rotateX(5deg) rotateY(-22deg) rotate(2deg);
    }
}

/*--------------------------------------/
 Customs
--------------------------------------*/

.muted-text {
    opacity: 0.6;
}

.faded-text {
    opacity: 0.3;
}

.solid-text {
    font-weight: bold;
    text-transform: uppercase;
    font-family: $solid-family;
    line-height: 1.2;
}

.heading-text {
    font-family: $text-family;
    font-weight: 700;
}

.sub-heading-text {
    @include solid-family();
}

.ellipsis-text{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 100%;
}

.no-wrap-text{
    white-space: nowrap;
}

/*--------------------------------------/
 Links
--------------------------------------*/

.link {
    cursor: pointer;
    text-decoration: underline;
}

.action-text {
    cursor: pointer;
}

/*--------------------------------------/
 Headers
--------------------------------------*/

.line-header {
    text-align: center;
    width: 100%;
    overflow: hidden;

    &.-muted {
        opacity: 0.3;
    }

    span {
        position: relative;
        padding: 0 1em;

        &:after, &:before {
            content: '';
            position: absolute;
            top: 50%;
            min-width: 50vw;
            border-bottom: 1px solid;
            height: 1px;
            opacity: 0.2;

            @include media(xs) {
                display: none;
            }
        }

        &:after {
            right: 100%;
        }

        &:before {
            left: 100%;
        }
    }
}


@each $key, $value in $headings {
    @include create-text-size('.#{$key}', $value);
    @include create-text-size('#{$key}', $value);
}

@each $key, $value in $responsive-headings {
    @each $breakpoint, $size in $value {
        @include create-responsive-text-size('.#{$key}', $breakpoint, $size);
    }
}


@each $key, $value in $display-headers {
    @include create-text-size('.#{$key}', $value);
}

@each $key, $value in $responsive-displays-headers {
    @each $breakpoint, $size in $value {
        @include create-responsive-text-size('.#{$key}', $breakpoint, $size);
    }
}


@each $color, $value in $theme-colors {
    @include create-text-color('.text-#{$color}', $value);
}

@each $color, $value in $grays {
    @include create-text-color('.text-#{$color}', $value);
}
