.carousel {
    position: relative;
    width: 100%;
    max-width: 540px;
    min-height: 100px;
    margin: 60px auto;
    border: 3px solid #e0e0e0;
    text-align: center;

    &:before,
    &:after  {
        content: "";
        position: absolute;
        left: 50%;
        width: 4px;
        margin-left: -2px;
        background-color: #e0e0e0;
    }

    &:before {
        bottom: 100%;
        height: 33px;
        background-image: linear-gradient(to bottom, #ffffff, rgba(255, 255, 255, 0));
    }

    &:after {
        top: 100%;
        height: 63px;
    }
}

.carousel-item-dots {
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);

    .carousel-item-dot {
        float: left;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #222;
        cursor: pointer;
    }

    .carousel-item-dot:not(.active) {
        opacity: 0.2;
    }

    .carousel-item-dot:not(:first-child) {
        margin-left: 5px;
    }
}

.carousel-nav {
    .ft2-btn {
        position: absolute;
        top: 0;
        width: 40px;
        height: 100%;
        padding: 0;
        text-align: center;
        color: inherit;
        background-color: transparent;
        box-shadow: none;
        opacity: 0.5;

        &.disabled {
            opacity: 0.1;
        }

        &.-left {
            left: 0;
        }

        &.-right {
            right: 0;
        }
    }
}
