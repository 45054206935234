@import "~quill/dist/quill.snow.css";
@import "../globals";

$editor-base-width: 640px;

.ft2-edit-dialog-trigger {
    cursor: pointer;
}

.ft2-edit-dialog {
    min-width: $editor-base-width + 30px;
    height: 100%;
    margin: 0 auto;
    color: $white;
    display: inline-flex;
    flex-direction: column;

    @include media(sm) {
        min-width: 320px;
        width: 100%;
    }

    &_body {
        margin-top: auto;
        margin-bottom: auto;
        padding: 60px 15px 90px;
    }

    &_header {
        margin-bottom: 30px;
    }

    &_heading {
        max-width: $editor-base-width;
        font-size: 28px;
        font-weight: 500;
        line-height: 1.2;
    }

    &_summary {
        max-width: $editor-base-width;
        margin-top: 15px;
        opacity: 0.7;
        word-break: break-word;
    }

    &_form {
        margin-top: 20px !important;
    }

    &_label {
        color: $white;
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 5px;
        display: block;
    }

    &_content {
        background: #fff;
        padding: 15px;
    }

    &_footer {
        text-align: right;
        margin-top: 30px;
    }

    select {
        padding: 5px;
        border-radius: 2px;
        border: none;
    }
}


.ft2-html-editor {
    position: relative;
    background: $white;
    margin-bottom: 20px;
    overflow: hidden;
    border-radius: 4px;
    color: $base-color;

    &_label {
        color: $gray-500;
        position: absolute;
        padding: 15px 15px 0 15px;
        z-index: 1;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        line-height: 1.5;
        font-size: 14px;
    }
}

.ft2-editor-panel {
    background: $gray-100;
    padding: 4px 10px;
    min-height: 42px;
    border-radius: 4px 4px 0 0;

    .ft2-select {

        .ng-dropdown-panel {
            width: auto !important;
            left: auto !important;
            right: 0 !important;
        }
    }


    & + * {
        border-radius: 0 0 4px 4px;
    }
}

.ft2-list-editor {
    .ng-select-container {
        border: none !important;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
}

.ft2-html-editor-panel {
    height: 42px;
    position: absolute;
    width: 100%;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
    padding: 8px;
    z-index: 1;
    border-bottom: 1px solid $gray-300;
    background: $gray-100;

    .mat-icon {
        color: $gray-600;

        &:hover {
            color: $secondary-color;
        }
    }

    &.-inactive {
        opacity: 0.3;
        pointer-events: none;
        cursor: default;
    }
}

.ft2-html-editor-wrapper {
    height: 200px;

    .ql-container.ql-snow {
        border-bottom: none;
    }

    &.-has-label {
        ngx-monaco-editor, quill-editor .ql-editor {
            padding-top: 40px;
        }
    }

    &.-plain {
        ngx-monaco-editor, quill-editor .ql-editor {
            padding-top: 20px;
        }
    }

    &.-border-top {
        border-top: 1px solid $gray-200;
    }

    [quill-editor-toolbar] {
        position: absolute;
        top: 0;
        border: none;
        z-index: 2;

        .ql-stroke {
            stroke: $gray-600;
        }

        .ql-underline svg {
            margin-top: 1px;
        }
    }

    ngx-monaco-editor {
        border: 1px solid #ccc;
        border-top: none;
        height: 100%;
    }
}

.ft2-html-editor-container {
    position: relative;
    padding-top: 42px;

    .ft2-html-editor.-plain &{
        padding-top: 0;
    }
}

.ft2-edit-dialog_modal {
    color: $base-color;
    background: $white;
    padding: 15px;
    border-radius: 4px;

    &.-options-list {
        min-height: 300px;
        max-height: 300px;
        overflow-y: auto;
    }
}

.ft2-editor-list-content {
    font-weight: 300;
    max-width: $editor-base-width;
    max-height: 350px;
    overflow: auto;

    .ft2-radio + label {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding-top: 10px;
        padding-bottom: 10px;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .ft2-checkbox {
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

