$displays: none, inline, inline-block, block, table, table-row, table-cell, flex, inline-flex !default;

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
        @each $value in $displays {
            .d#{$infix}-#{$value} {
                display: $value !important;
            }
        }
    }
}
