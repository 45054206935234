.ft-popup {
    position: absolute;
    top: auto;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: -5px;
    padding: 0 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 106px;
    min-height: 30px;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    line-height: 1.4;
    color: $base-color;
    background: $white;
    box-shadow: $popup-shadow;
    border-radius: 4px;
    cursor: pointer;
    z-index: 3;
    border: 1px solid rgba(#222222, .15);
    transition: all .3s cubic-bezier(0, 0, 0.1, 1);
    animation: popupSlideUp .3s cubic-bezier(0, 0, 0.1, 1);

    &.-hidden {
        opacity: 0;
        transition-delay: 3s;
    }
}

@keyframes popupSlideUp {
    0% {
        transform: translateX(-50%) translateY(15px);
        opacity: 0;
    }

    100% {
        transform: translateX(-50%) translateY(0);
        opacity: 1;
    }
}
