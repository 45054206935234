@import "../../../shared-assets/styles/globals";
$journey-green: #66AB75;
$journey-cranberry: #D5498E;
$journey-blue: #486FD9;

.ft2-journey {

    &.-theme_blue {
        .ft2-journey-section:before,
        .ft2-journey-section:after {
                background: lighten($journey-blue, 20%);
        }

        .ft2-journey-circle, 
        .ft2-journey-label {
            background: linear-gradient(to bottom, $journey-blue, lighten($journey-blue, 10%));
            color: white;
        }

        .ft2-journey-frame {
            border-color: lighten($journey-blue, 10%);
        }
    }

    &.-theme_striped {
        .ft2-journey-section:before, 
        .ft2-journey-circle, 
        .ft2-journey-label {
            background-image: $striped-gradient;
            background-size: 7.00px 7.00px;
        }

        .ft2-journey-frame {
            border-color: $gray-200;
        }
    }
}

.ft2-journey-nav {
    display: flex;
    justify-content: center;
}

.ft2-carousel-nav {
    position: relative;
    height: 525px;
    margin-top: 90px;
    z-index: 1;
}

.ft2-carousel-group {
    transform: translateX(-1%);
    font-size: 0;
    white-space: nowrap;
    text-align: center;

    &:before {
        content: " ";
        display: table;
    }

    &:after {
        content: " ";
        clear: both;
    }

    .ft2-carousel-group-item {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        height: 525px;
        width: 3vw;
        min-width: 0px;
        max-width: 40px;
        font-size: 16px;
        white-space: normal;
        transition: 0.5s cubic-bezier(0, 0, 0.1, 1);
        transition-property: width, min-width, max-width;
        cursor: pointer;
        border-top: 1px solid #c3c6c9;
        perspective: 1000px;

        &:before {
            content: "";
            position: absolute;
            top: -1px;
        }

        &:after {
            content: "";
            position: absolute;
            top: -1px;
        }

        &.spacing-item {
            border-top: none;
        }

        &:last-child {
            right: 0;
            height: 10px;
            border-left: 1px solid #c3c6c9;
            border-right: 2px solid #fff;
        }

        &.active {
            width: 80vw;
            min-width: 290px;
            max-width: 800px;
            cursor: default;
            z-index: 20;
        }

        .ft2-carousel-group-label {
            position: absolute;
            bottom: 100%;
            left: 0;
            margin-bottom: 5px;
            width: 100%;
            font-size: 12px;
            white-space: nowrap;
            transition: 0.5s cubic-bezier(0.7, 0, 0.1, 1);
            transition-property: left, transform;
            pointer-events: none;
        
            &:before {
                content: "";
                position: absolute;
                top: 0;
                right: 100%;
                width: 3vw;
                height: 100%;
                background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
            }
        }

        .ft2-carousel-card {
            position: absolute;
            top: 50%;
            transition: 0.5s cubic-bezier(0, 0, 0.1, 1);
            transition-property: transform, transform-origin;
            transform: rotateY(0) translate3d(0, -50%, 0);
            transform-origin: 50% 50%;
        }

        &.active-after {
            .ft2-carousel-card {
                right: 0;
                background-color: #f3f3f6;
                box-shadow: 0 4px 8px rgb(0 0 0 / 10%), 50px 30px 90px -20px rgb(0 0 0 / 5%);
                transform: rotateY(-66deg) translate3d(0, -50%, 0) scale(0.75);
                transform-origin: 100% 50%;
                overflow: hidden;
            }

            &.ft2-persona-scheme-guests {
                .ft2-carousel-card {
                    box-shadow: 3px 0 0 0 #66ab75, 0 4px 8px rgb(0 0 0 / 10%), 50px 30px 90px -20px rgb(0 0 0 / 5%) !important;
                }
            }

            &.ft2-persona-scheme-friends {
                .ft2-carousel-card {
                    box-shadow: 3px 0 0 0 #d5498e, 0 4px 8px rgb(0 0 0 / 10%), 50px 30px 90px -20px rgb(0 0 0 / 5%) !important;
                }
            }

            &.ft2-persona-scheme-visitors {
                .ft2-carousel-card {
                    box-shadow: 3px 0 0 0 #486fd9, 0 4px 8px rgb(0 0 0 / 10%), 50px 30px 90px -20px rgb(0 0 0 / 5%) !important;
                }
            }
        }

        &.active-after-1 {
            width: 7.5vw;
            max-width: 100px;
            z-index: 11;
        }

        &.active-after-2 {
            z-index: 10;
        }

        &.active-after-3 {
            z-index: 9;
        }

        &.active-after-4 {
            z-index: 8;
        }

        &.active-after-5 {
            z-index: 7;
        }

        &.active-after-6 {
            z-index: 6;
        }

        &.active-after-7 {
            z-index: 5;
        }

        &.active-before {
            .ft2-carousel-card {
                left: 0;
                background-color: #f3f3f6;
                box-shadow: 0 4px 8px rgb(0 0 0 / 10%), -50px 30px 90px -20px rgb(0 0 0 / 5%);
                transform: rotateY(66deg) translate3d(0, -50%, 0) scale(0.75);
                transform-origin: 0% 50%;
                overflow: hidden;
            }
    
            &.ft2-persona-scheme-guests {
                .ft2-carousel-card {
                    box-shadow: -3px 0 0 0 #66ab75, 0 4px 8px rgb(0 0 0 / 10%), -50px 30px 90px -20px rgb(0 0 0 / 5%) !important;
                }
            }
    
            &.ft2-persona-scheme-friends {
                .ft2-carousel-card {
                    box-shadow: -3px 0 0 0 #d5498e, 0 4px 8px rgb(0 0 0 / 10%), -50px 30px 90px -20px rgb(0 0 0 / 5%) !important;
                }
            }
    
            &.ft2-persona-scheme-visitors {
                .ft2-carousel-card {
                    box-shadow: -3px 0 0 0 #486fd9, 0 4px 8px rgb(0 0 0 / 10%), -50px 30px 90px -20px rgb(0 0 0 / 5%) !important;
                }
            }
        }

        &.active-before-1 {
            width: 7.5vw;
            max-width: 100px;
            z-index: 11;
        }

        &.active-before-2 {
            z-index: 10;
        }

        &.active-before-3 {
            z-index: 9;
        }

        &.active-before-4 {
            z-index: 8;
        }

        &.active-before-5 {
            z-index: 7;
        }

        &.active-before-6 {
            z-index: 6;
        }

        &.active-before-7 {
            z-index: 5;
        }

        &.slider-offset-0 {
            width: 0;
            min-width: 0;
            max-width: 0;
        }

        &.slider-offset-1 {
            width: 3vw;
            max-width: 40px;
          }

          &.slider-offset-2 {
            width: 6vw;
            max-width: 80px;
          }

          &.slider-offset-3 {
            width: 9vw;
            max-width: 118px;
          }

          &.slider-offset-4 {
            width: 12vw;
            max-width: 160px;
          }

          &.slider-offset-5 {
            width: 15vw;
            max-width: 200px;
          }

          &.slider-offset-6 {
            width: 18vw;
            max-width: 240px;
          }

          &.slider-offset-7 {
            width: 21vw;
            max-width: 339px;
          }

          &.slider-offset-8 {
            width: 24vw;
            max-width: 320px;
          }
          &.slider-offset-9 {
            width: 27vw;
            max-width: 360px;
          }

          &.slider-offset-10 {
            width: 30vw;
            max-width: 400px;
          }
    }
}

.ft2-participant-incentive {
    max-width: 760px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
    padding-bottom: 2rem;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-top: 0;

    .ft2-supertitle-divider {
        display: table;
        width: 100%;
        text-align: center;
        margin-bottom: 0;

        &:before {
            content: " ";
            display: table-cell;
            vertical-align: middle;
            width: 50%;
            border-top: 1px solid;
            opacity: 0.5;
        }

        &:after {
            content: " ";
            display: table-cell;
            vertical-align: middle;
            width: 50%;
            border-top: 1px solid;
            opacity: 0.5;
        }

        .ft2-supertitle {
            display: table-cell;
            vertical-align: middle;
            position: relative;
            top: -0.5em;
            padding-left: 15px;
            padding-right: 15px;
            white-space: nowrap;

            mat-icon {
                position: absolute;
                top: 20%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 60px;
                height: 60px;
                opacity: 0.3;
            }
        }
    }
}

.ft2-contexttabs-nav {
    ul {
        position: relative;
        font-weight: bold;
        letter-spacing: 0.025em;
        line-height: 1.2;
        text-align: left;
        padding: 0;

        .active {
            text-transform: uppercase;
            color: white;
        }

        &:before {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            border-bottom: 1px solid;
            opacity: 0.2;
        }

        &:after {
            content: " ";
            display: table;
        }

        li {
            display: inline-block;
            transition: opacity 0.3s cubic-bezier(0, 0, 0.1, 1);
            position: relative;

            a {
                display: block;
                padding: 10px 0;
                opacity: 0.5;
            }

            &:before {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                border-bottom: 2px solid;
            }

            &.active:before {
                transition: 0.3s cubic-bezier(0.7, 0, 0.1, 1);
                transition-property: opacity, transform;
            }

            &.ft2-navitem-action {
                float: right;
                margin-right: 0;
            }
        }

        li:not(.active):before {
            opacity: 0;
            transform: scaleX(0.5);
        }
    }
}

.ft2-campaign {
    position: relative;
    background: #fff;
    border: 1px solid #e0e0e0;
    transition: 0.3s cubic-bezier(0, 0, 0.1, 1);
    transition-property: box-shadow;
    min-height: 180px;
    color: #222;
    border-left: 4px solid #90999f;
    cursor: pointer;

    &.-other {
        border-left-color: #4A90E2;
    }

    &.-custom {
        border-left-color: #D5498E;
    }

    .campaign-stats {
        color: #4A90E2;

        &:before {
            content: " ";
            display: table;
        }

        &:after {
            content: " ";
            display: table;
        }

        .stat {
            float: left;
            width: 50%;
        }
    }
}

.ft2-journey-link {
    color: #4A90E2 !important;
    text-decoration: none !important;

    mat-icon {
        width: 14px;
        height: 14px;
    }
}

.ft2-carousel-card {
    position: relative;
    width: 80vw;
    min-width: 290px;
    max-width: 800px;
    height: 450px;
    text-align: center;
    color: #222;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    box-shadow: 0 4px 8px rgb(0 0 0 / 10%);
}

.ft2-journeys-container-bg {
    position: absolute !important;
    overflow: hidden;
    display: block;
    transform: skewY( -8deg);
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 82%;
}

.ft2-journey-group {
    height: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;
}

.ft2-journey-card {
    height: 450px;
    width: 80vw;
    min-width: 290px;
    max-width: 800px;
    box-shadow: $card-shadow;
    border: 1px solid $gray-200;
    padding: 30px 30px 0;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    &_summary {
        max-width: 32em;
        margin: 20px auto;
    }

    &_person {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: -50px;
    }
}

.ft2-journey-frame {
    position: relative;
    border: 6px solid $gray-200;
    background: white;
    padding: 30px;
    z-index: 1;
    text-align: center;

    &.-green {
        border-color: $journey-green;
    }

    &.-cranberry {
        border-color: $journey-cranberry;
    }

    &.-blue {
        border-color: $journey-blue;
    }
}


.ft2-journey-profile {
    padding: 20px;
    border-radius: 50%;
    width: 128px;
    height: 128px;
}

.ft2-journey-section {
    position: relative;

    padding: 90px 0;

    @include media(md) {
        padding: 70px 0;
    }

    @include media(sm) {
        padding: 60px 0;
    }

    &.-large {
        padding: 120px 0;

        @include media(md) {
            padding: 90px 0;
        }

        @include media(sm) {
            padding: 70px 0;
        }
    }

    &.-small {
        padding: 60px 0;

        @include media(md) {
            padding: 50px 0;
        }

        @include media(sm) {
            padding: 40px 0;
        }
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -4px;
        width: 8px;
        height: 100%;
        background-image: $striped-gradient;
        background-size: 7.00px 7.00px;

        @include media(md) {
            opacity: 0.5;
        }
    }

    &.-path_blue:before {
        background: lighten($journey-blue, 20%) !important;
    }

    &.-path_green:before {
        background: lighten($journey-green, 20%) !important;
    }

    &.-path_cranberry:before {
        background: lighten($journey-cranberry, 20%) !important;
    }

    &.-path_gray:before {
        width: 2px;
        background: linear-gradient(to bottom, $gray-500, $white);
        margin-left: -1px;
    }

    &.-path-none:before {
        content: none;
    }

    &.-path-half-bottom:before {
        height: 50%;
    }

    &.-path-junction {
        &:before, &:after {
            content: "";
            position: absolute;
            left: 50%;
            margin-left: -10px;
            width: 20px;
            height: 20px;
            border-radius: 100%;
            opacity: 1 !important;
        }

        &:before {
            top: -10px;
        }
        &:after {
            bottom: -10px;
        }
    }

    &_content {
        position: relative;
        z-index: 1;
    }

    &_circle {
        min-width: 140px;
        width: 20%;
    }

    &:last-child {
        padding-bottom: 0 !important;
    }

    &.-bg-light {
        background-color: $light-color;
    }
}

.ft2-journey-label {
    background: $gray-300;
    min-width: 250px;
    padding: 12px 30px;
    min-height: 50px;
    font-family: $solid-family;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    border-radius: 25px;
    position: relative;
    z-index: 1;

    &.-striped {
        background-size: 7.00px 7.00px !important;
        background-image: $striped-gradient !important;
    }
}

.ft2-journey-circle {
    position: relative;
    z-index: 1;
    min-width: 128px;
    min-height: 128px;
    width: 128px;
    height: 128px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: $gray-300;

    // &.-small {
    //     padding: 60px 0;

    //     @include media(md) {
    //         padding: 50px 0;
    //     }

    //     @include media(sm) {
    //         padding: 40px 0;
    //     }
    // }


    &.-pure {
        background: none !important;
    }

    &.-solid {
        &:before {
            width: 85%;
            height: 85%;
        }
    }

    &.-striped {
        background-size: 7.00px 7.00px !important;
        background-image: $striped-gradient !important;
    }

    &.-blue {
        background-image: linear-gradient(to bottom, $journey-blue, lighten($journey-blue, 10%)) !important;
        background-size: 100% !important;
    }

    &.-green {
        background-image: linear-gradient(to bottom, $journey-green, lighten($journey-green, 10%)) !important;
        background-size: 100% !important;
    }

    &.-cranberry {
        background-image: linear-gradient(to bottom, $journey-cranberry, lighten($journey-cranberry, 10%)) !important;
        background-size: 100% !important;
    }

    &_profile {
        background-image: url($image-path + 'assets/img/profile/ft-profile-anon2.jpg');
        background-color: $red;
        background-size: cover;
        border: 6px solid $white;
        border-radius: 50%;
        height: 90%;
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        font-family: $solid-family;
        color: $white;
    }

    &_icon {
        min-width: 40%;
        min-height: 40%;
    }

    > * {
        position: relative;
        z-index: 1;
    }

    &:before {
        content: '';
        background: $white;
        width: 90%;
        height: 90%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        z-index: 0;
    }
}

.ft2-info-block {
    position: relative;
    display: inline-block;
    max-width: 35em;
    margin: 0 auto;
    padding: 15px 30px;
    font-size: 14px;
    line-height: 1.4;
    color: $base-color;
    background-color: $white;
    border-radius: 4px;
    box-shadow: 0 0 2px 0 rgb(0 0 0 / 30%), 0 8px 8px -4px rgb(0 0 0 / 30%);

    &:before {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        margin-top: -16px;
        border: 15px solid transparent;
        border-right-color: $white;
        border-bottom-color: $white;
        box-shadow: 3px 3px 4px 0 rgb(0 0 0 / 20%);
    }

    &.-top {
        &:before {
            top: auto;
            margin-top: 0;
            bottom: 100%;
            margin-bottom: -15px;
            transform: translateX(-50%) rotate(-135deg);
            box-shadow: 2px 2px 1px 0 rgb(0 0 0 / 10%);
        }
    }
}

.ft2-journey-editor-nav.ft-sidebar-tabs {
    .ft-sidebar-tabs_nav {
        list-style: none;

        li.-active {
            color: #00334e !important;
            border-color: #00334e !important;
        }
    }
}

.ft2-profile-pic {
    position: relative;
    width: 80px;
    height: 80px;
    line-height: 1;
    text-align: center;
    margin: 0 auto;
    background-color: #ececef;
    border-radius: 50% !important;
    overflow: hidden;
}

.ft2-content {
    width: 100%;
    padding: 30px;
    margin-bottom: 20px;
    text-align: center;
    border: 1px solid #e0e0e0;
    height: 200px;

    &.-non-editable {
        background-color: #f3f3f6;
    }
}
