hr {
    height: 1px;
    border: none;
    margin: 30px 0;
    background: $base-color;
    opacity: .2;

    &.hr-vertical {
        margin: 0 10px;
        display: inline-block;
        height: 1em;
        width: 1px;
        background: none;
        border-left: 1px solid;
    }

    &.-gray {
        background: $gray-200;
    }

    &.-solid {
        opacity: 1;
    }
}

.ft2-hr {
    height: 1px;
    border: none;
    margin: 0;
    background: $base-color;
    opacity: .2;
}

.ft2-labelled-hr {
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    &:before,
    &:after {
        content: "";
        display: block;
        flex: auto;
        height: 1px;
        background: $base-color;
        opacity: .2;
    }

    &:before {
        margin-right: 15px;
    }

    &:after {
        margin-left: 15px;
    }

    &.-light {
        &:before, &:after {
            background: $white;
            opacity: .3;
        }
    }

    &.-solid {
        &:before, &:after {
            opacity: 1;
        }
    }
}
