html {
    min-height: 100%;
    height: 100%;
}

body {
    -webkit-font-smoothing: auto;
    //-webkit-tap-highlight-color: rgba($dark, 0.1);
    -webkit-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    touch-action: manipulation;
    text-rendering: optimizeLegibility;
    font-family: $text-family;
    color: $base-color;
    font-weight: 300;
    margin: 0;
}



