$checkbox: (
    size: 20px,
    check-size: #{var(--ft2-checkbox-check-size, 30px)},
    background-color: $white,
    border-color: $gray-500,
    border-width: 2px,
    mark-color: #{var(--ft2-checkbox-mark-color, $primary-color)},
    label-margin: 15px,
    border-radius: 0,
    padding: 15px,
    duration: .2s,
    shadow-size: #{var(--ft2-checkbox-shadow-size, 2px)},
    check-x-shift: #{var(--ft2-checkbox-check-x-shift, 0px)},
    check-y-shift: #{var(--ft2-checkbox-check-y-shift, 0px)},
    check-l-height: #{var(--ft2-checkbox-check-l-height, .35)},
    check-r-height: #{var(--ft2-checkbox-check-r-height, .7)},
    check-weight: #{var(--ft2-checkbox-check-weight, .15)}
);

.ft2-checkbox {
    cursor: pointer;
    display: inline-flex;
    align-items: flex-start;

    &_control {
        height: map-get($checkbox, 'size');
        width: map-get($checkbox, 'size');
        min-width: map-get($checkbox, 'size');
        background-color: transparent;
        border: map-get($checkbox, 'border-width') solid map-get($checkbox, 'border-color');
        border-radius: map-get($checkbox, 'border-radius');
        position: relative;
        display: inline-block;
        transition: border-color ease map-get($checkbox, 'duration')*0.5;
        cursor: pointer;
        margin-right: map-get($checkbox, 'label-margin');

        &::before, &::after {
            position: absolute;
            height: 0;
            width: calc(map-get($checkbox, 'check-size') * map-get($checkbox, 'check-weight'));
            background-color: map-get($checkbox, 'mark-color');
            display: inline-block;
            transform-origin: left top;
            border-radius: 1px;
            content: ' ';
            transition: opacity ease .5s;
            opacity: 0;
        }

        &::before {
            top: calc(map-get($checkbox, 'check-size') * .48 + map-get($checkbox, 'check-y-shift'));
            left: calc(map-get($checkbox, 'check-size') * .28 + map-get($checkbox, 'check-x-shift'));
            box-shadow: 0 0 0 map-get($checkbox, 'shadow-size') map-get($checkbox, 'background-color');
            transform: rotate(-135deg);
        }

        &::after {
            top: calc(map-get($checkbox, 'check-size') * .2 + map-get($checkbox, 'check-y-shift'));
            left: calc(map-get($checkbox, 'check-size') * 0 + map-get($checkbox, 'check-x-shift'));
            transform: rotate(-45deg);
        }
    }

    &.-disabled {
        opacity: .6;
    }

    input[type=checkbox] {
        display: none;
    }

    input[type=checkbox]:checked + .ft2-checkbox_control {
        &::after {
            opacity: 1;
            height: calc(map-get($checkbox, 'check-size') * .5);
            animation: dothabottomcheck map-get($checkbox, 'duration')*0.5 ease 0s forwards;
        }

        &::before {
            opacity: 1;
            height: calc(map-get($checkbox, 'check-size') * 1.2);
            animation: dothatopcheck map-get($checkbox, 'duration') ease 0s forwards;
        }
    }

    @keyframes dothabottomcheck {
        0% {
            height: 0;
        }
        100% {
            height: calc(map-get($checkbox, 'check-size') * map-get($checkbox, 'check-l-height'));
        }
    }

    @keyframes dothatopcheck {
        0% {
            height: 0;
        }
        50% {
            height: 0;
        }
        100% {
            height: calc(map-get($checkbox, 'check-size') * map-get($checkbox, 'check-r-height'));
        }
    }
}
