/// Grid system
//
// Generate semantic grid columns with these mixins.

@use "sass:math";

@mixin make-gutters($gutters: $grid-gutter-widths){
    @each $breakpoint in map-keys($gutters){
        @include media-breakpoint($breakpoint){
            $gutter: map-get($gutters, $breakpoint);
            padding-right: ($gutter * 0.5);
            padding-left: ($gutter * 0.5);
        }
    }
}

@mixin make-row($gutters: $grid-gutter-widths, $breakpoints: $grid-breakpoints){
    display: flex;
    flex-wrap: wrap;
    flex: 1;

    @each $breakpoint in map-keys($gutters){
        @include media-breakpoint($breakpoint){
            $gutter: map-get($gutters, $breakpoint);
            margin-right: math.div($gutter, -2);
            margin-left: math.div($gutter, -2);
        }
    }
}

@mixin make-col-ready($gutters: $grid-gutter-widths){
    position: relative;
    // Prevent columns from becoming too narrow when at smaller grid tiers by
    // always setting `width: 100%;`. This works because we use `flex` values
    // later on to override this initial width.
    width: 100%;
    min-height: 1px; // Prevent collapsing

    @each $breakpoint in map-keys($gutters){
        @include media-breakpoint($breakpoint){
            $gutter: map-get($gutters, $breakpoint);
            padding-right: ($gutter * 0.5);
            padding-left: ($gutter * 0.5);
        }
    }
}

@mixin make-col($size, $columns: $grid-columns){
    flex: 0 0 percentage(math.div($size, $columns));
    // width: percentage($size / $columns);
    // Add a `max-width` to ensure content within each column does not blow out
    // the width of the column. Applies to IE10+ and Firefox. Chrome and Safari
    // do not appear to require this.
    max-width: percentage(math.div($size, $columns));
}

@mixin make-col-offset($size, $columns: $grid-columns){
    margin-left: percentage(math.div($size, $columns));
}

@mixin make-col-push($size, $columns: $grid-columns){
    left: if($size > 0, percentage(math.div($size, $columns)), auto);
}

@mixin make-col-pull($size, $columns: $grid-columns){
    right: if($size > 0, percentage(math.div($size, $columns)), auto);
}

@mixin make-col-modifier($type, $size, $columns){
    // Work around the lack of dynamic mixin @include support (https://github.com/sass/sass/issues/626)
    @if $type == push{
        @include make-col-push($size, $columns);
    } @else if $type == pull{
        @include make-col-pull($size, $columns);
    } @else if $type == offset{
        @include make-col-offset($size, $columns);
    }
}

/*--------------------------------------/
 Make grid
--------------------------------------*/

@mixin make-grid-cell($size){
    flex: 0 0 percentage(math.div(1, $size));
    max-width: percentage(math.div(1, $size));
}

@mixin make-grid($prefix: '', $columns: $grid-columns, $breakpoints: $grid-breakpoints, $gutters: $grid-gutter-widths){
    @each $breakpoint in map-keys($breakpoints){
        $infix: breakpoint-infix($breakpoint, $breakpoints);


        @include media-breakpoint($breakpoint, $breakpoints){
            @for $i from 1 through $columns{
                .#{$prefix}grid#{$infix}-#{$i}{

                    $gutter: map-get($gutters, $breakpoint);
                    display: flex;
                    flex-wrap: wrap;
                    margin-right: math.div($gutter, -2);
                    margin-left: math.div($gutter, -2);

                    > .#{$prefix}cell{
                        position: relative;
                        width: 100%;
                        min-height: 1px; // Prevent columns from collapsing when empty
                        @include make-grid-cell($i);
                        @include make-gutters($gutters: $grid-gutter-widths);
                    }
                }
            }
        }
    }
}
