.ft-snapshot {
    padding: 45px 15px;
    background-image: url("/dist/v2-hybrid/ft-pattern-pinstripe.png");
    background-size: 6px 6px;
    background-repeat: repeat;
}

.ft-snapshot-preview {
    padding: 20px;
    border: 1px solid $gray-200;
    display: flex;
    min-height: 80px;
    margin-bottom: 20px;
    cursor: pointer;
    transition: 0.3s cubic-bezier(0, 0, 0.1, 1);

    &:hover {
        box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.2);
    }

    &_label {
        width: 40%;
        min-width: 120px;
        font-weight: bold;
    }
}
