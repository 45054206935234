@media print {
    .cdk-global-scrollblock {
        overflow-y: auto !important;
        position: initial !important;
    }

    ft-account-app,
    .ft2-btn-close { 
        display: none !important; 
    }

    .cdk-overlay-container,
    .cdk-global-overlay-wrapper,
    .cdk-overlay-pane {
        position: initial !important;
    }

    .ft2-light-dialog {
        padding-top: 0 !important;
    }

    .ft2-light-dialog_controls {
        display: none !important;
    }

    * {
        color: #222 !important;
    }
}