.ft2-tag {
    display: inline-flex;
    padding: 5px 15px;
    align-items: center;
    background: $gray-100;
    border-radius: 2px;
    cursor: pointer;
    font-weight: normal;
    justify-content: space-between;
    margin-bottom: 5px;
    white-space: nowrap;
    margin-right: 5px;

    &:last-child {
        margin-right: 0;
    }

    :has(.ft2-tag_count) {
        padding: 5px 5px 5px 15px;
    }

    &_count {
        display: flex;
        min-width: 23px;
        min-height: 23px;
        height: 23px;
        padding: 0 2px;
        margin-left: 10px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        background: white;
    }
}
