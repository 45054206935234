@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmYUtfBBc4AMP6lQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

$alt1-family: 'Roboto', museo-sans, sans-serif;
$alt2-family: 'Brandon Grotesque', sans-serif;

.ft-feature-card-col {
    // Set container query reference for card
    container: ft-feature-card-col / inline-size;
}

ft-feature-card {
    position: relative;
}

.ft-feature-card {
    position: relative;
    width: 100cqw;
    height: 125cqw;
    color: $white;
    background-color: $violet;
    background-size: cover;
    background-position: center center;
    border-radius: 2px;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: all $transition;
    transition-property: transform, box-shadow, opacity;

    // Set container query reference for card content
    container: ft-feature-card / inline-size;

    // Fallback if browser does not support container query
    @supports not (container-type: inline-size) {
        height: 350px;
    }

    // --------------------------------------------- Modifiers

    &.-disabled {
        cursor: default;
        pointer-events: none;
    }

    &.-inactive {
        &:before {
            content: '';
            @include overlay;
            z-index: 10;
            background: rgba($white, 0.5);
        }
    }

    &.-overlay {
        &:not(.-disabled):hover:after {
            opacity: 0.8;
        }

        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(135deg, rgba($black, 0.5) 30%, rgba($black, 0.1) 100%);
            transition: opacity .2s linear;
        }
    }

    &:not(.-disabled):hover {
        transform: translate(0, -5px);
        box-shadow: 0 8px 16px rgba(0, 0, 0, .3)
    }

    &.-light {
        background-color: $white;
        color: $violet;
    }

    &.-dark {
        background-color: $violet;
        color: $white;
    }

    &.-large {
        max-width: 450px;
        max-height: 450px * 1.25;
    }

    &.-medium {
        max-width: 300px;
        max-height: 300px * 1.25;
    }

    &.-small {
        max-width: 225px;
        max-height: 225px * 1.25;
    }

    // Fallback if browser does not support container query
    @supports not (container-type: inline-size) {
        &.-large {
            max-width: 450px;
            height: 570px;

            .ft-feature-card_content,
            .ft-feature-card_term {
                font-size: 24px; // Base font-size for all elements inside card
            }
        }

        &.-medium {
            max-width: 300px;
            height: 380px;

            .ft-feature-card_content,
            .ft-feature-card_term {
                font-size: 16px; // Base font-size for all elements inside card
            }
            .ft-feature-card_content {
                padding: 60px 20px;
            }

            .ft-feature-card_term {
                bottom: 20px;
                left: 20px;
            }
        }

        &.-small {
            max-width: 225px;
            height: 275px;
            padding: 45px 15px;

            .ft-feature-card_content,
            .ft-feature-card_term {
                font-size: 12px; // Base font-size for all elements inside card
            }

            .ft-feature-card_content {
                padding: 90px 36px;
            }

            .ft-feature-card_term {
                bottom: 36px;
                left: 36px;
            }

            // .ft-feature-card_title {
            //      overflow: hidden;
            //      text-overflow: ellipsis;
            // }
        }
    }

    // --------------------------------------------- Elements

    &_content {
        // primary space for card info
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
        padding: 20cqw 8cqw;
        font-size: 5.35cqw;

        // Fallback if browser does not support container query
        @supports not (container-type: inline-size) {
            padding: 3.74em 1.5em;
        }
    }

    &_term {
        // secondary space for card info
        position: absolute;
        bottom: 12cqw;
        left: 8cqw;
        right: 0;
        z-index: 1;
        font-size: 5.35cqw;

        // Fallback if browser does not support container query
        @supports not (container-type: inline-size) {
            bottom: 1.5em;
            left: 1.5em;
        }
    }

    p {
        line-height: 1.3;
    }

    &_title {
        font-family: $alt1-family;
        font-size: 14cqw;
        font-weight: 500;
        line-height: 1.1;
        display: flex;
        flex-direction: column;

        // Fallback if browser does not support container query
        @supports not (container-type: inline-size) {
            font-size: 2.666em;
        }

        &.-x-small {
            font-size: 5cqw;
            line-height: 1.5;

            // Fallback if browser does not support container query
            @supports not (container-type: inline-size) {
                font-size: 1.2em;
            }
        }

        &.-small {
            font-size: 8cqw;
            line-height: 1.2;

            // Fallback if browser does not support container query
            @supports not (container-type: inline-size) {
                font-size: 1.5em;
            }
        }

        &.-medium {
            font-size: 13cqw;
            line-height: 1.1;

            // Fallback if browser does not support container query
            @supports not (container-type: inline-size) {
                font-size: 2.4em;
            }
        }

        &.-large {
            font-size: 18cqw;
            line-height: 1;

            // Fallback if browser does not support container query
            @supports not (container-type: inline-size) {
                font-size: 3.333em;
            }
        }

        &.-huge {
            font-size: 28cqw;
            line-height: 1;

            // Fallback if browser does not support container query
            @supports not (container-type: inline-size) {
                font-size: 5.25em;
            }
        }

        &.-jumbo {
            font-size: 36cqw;
            line-height: .9;

            // Fallback if browser does not support container query
            @supports not (container-type: inline-size) {
                font-size: 6.666em;
            }
        }

        &.-alt2-bold {
            font-family: $alt2-family;
            font-weight: 600;
        }

        &.-skew-y-1 {
            transform: skewY(-19deg);
        }
    }

    &_subtitle {
        font-family: $alt1-family;
        font-size: 5.35cqw;
        font-weight: 700;

        // Fallback if browser does not support container query
        @supports not (container-type: inline-size) {
            font-size: 1em;
        }
    }

    &_coming-soon {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 15;
        color: #222;
        background: #fff;
        border-radius: 2px;
        padding: 0.5em 1em;
        @include solid-family(0.8em);
        letter-spacing: 0;
    }

    &_logo {
        // optional logo displayed on card
        position: absolute;
        height: 16cqw;
        z-index: 5;

        // Fallback if browser does not support container query
        @supports not (container-type: inline-size) {
            height: 40px;
        }

        &.-on-top {
            top: 12cqw;
            left: 8cqw;

            // Fallback if browser does not support container query
            @supports not (container-type: inline-size) {
                top: 30px;
                left: 20px;
            }
        }

        &.-on-bottom {
            bottom: 8cqw;
            right: 8cqw;

            // Fallback if browser does not support container query
            @supports not (container-type: inline-size) {
                bottom: 20px;
                right: 20px;
            }
        }
    }
    &_term {
        position: absolute;
        bottom: 10%;
        left: 30px;
        right: 0;
        z-index: 1;
        @include solid-family(inherit);
        letter-spacing: 0;

        &.-on-top {
            top: 12cqw;
            left: 8cqw;

            // Fallback if browser does not support container query
            @supports not (container-type: inline-size) {
                top: 30px;
                left: 20px;
            }
        }

        &.-on-bottom {
            bottom: 8cqw;
            right: 8cqw;

            // Fallback if browser does not support container query
            @supports not (container-type: inline-size) {
                bottom: 20px;
                right: 20px;
            }
        }
    }

    &_bottom {
        position: absolute;
        bottom: 8cqw;
        left: 8cqw;
        right: 8cqw;
        z-index: 1;
        word-break: break-all;

        // Fallback if browser does not support container query
        @supports not (container-type: inline-size) {
            bottom: 20px;
            left: 20px;
            right: 20px;
        }
    }

    ft-insight-visibility-label,
    ft-collaborative-visibility-label {
        position: absolute;
        top: 18px;
        right: 15px;
        z-index: 1;
        transition: opacity $transition;
    }

    &:not(.disabled):hover ft-collaborative-visibility-label {
        opacity: 0;
    }
}



// --------------------------------------------- Styling for specific insights

.ft-feature-card {
    // A Look Ahead
    .lookahead-decoration {
        &:after {
            content: '';
            display: block;
            margin-top: 0.3em;
            height: 0.6em;
            background: repeating-linear-gradient(to right,white,white 2px,transparent 0,transparent 6px);
        }
    }

    // Marketing Stack Comparison
    .techstack-decoration {
        position: relative;
        top: -20px;
        width: 50%;
        height: 6px;
        background: #fff;
        opacity: .6;

        &:before, &:after {
            content: "";
            position: absolute;
            top: 100%;
            left: 0;
            display: block;
            height: 4px;
            background: #fff;
        }

        &:before {
            width: 75%;
            margin-top: 6px;
        }

        &:after {
            width: 33%;
            margin-top: 16px;
        }
    }
}
