.swiper-container {
    max-width: 1050px;

    .swiper-button-next, .swiper-button-prev {
        &:focus {
            outline: none;
        }

        color: $gray-600;
    }

    .swiper-button-prev {
        left: 0;
    }

    .swiper-button-next {
        right: 0;
    }
}

.swiper-pagination {
    .swiper-pagination-rectangle {
        width: 20px;
        height: 4px;
        background: $gray-400;
        display: inline-block;
        margin: 0 1px;
        cursor: pointer;

        &-active {
            background: $gray-800;
        }
    }
}

.swiper-navigation{
    height: 60px;
}

.swiper-button-circle {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid $gray-400;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    transition: border $transition;

    &:hover {
        border-color: $gray-800;
    }
}
