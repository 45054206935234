.ft2-container {
    @include make-gutters();
    max-width: $container-width;
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    &.-half {
        max-width: $container-width * 0.5;
    }

    &.-narrower {
        max-width: $container-width-narrower;
    }

    &.-narrow {
        max-width: $container-width-narrow;
    }

    &.-wide {
        max-width: $container-width-wide;
    }

    &.-wider {
        max-width: $container-width-wider;
    }

    &.-full {
        max-width: 100%;
    }
}

.ft2-block {
    margin: 90px 0;

    @include media(md) {
        margin: 70px 0;
    }

    @include media(sm) {
        margin: 60px 0;
    }

    &.-large {
        margin: 120px 0;

        @include media(md) {
            margin: 90px 0;
        }

        @include media(sm) {
            margin: 70px 0;
        }
    }

    &.-small {
        margin: 60px 0;

        @include media(md) {
            margin: 50px 0;
        }

        @include media(sm) {
            margin: 40px 0;
        }
    }
}

.ft2-section {
    padding: 90px 0;

    @include media(md) {
        padding: 70px 0;
    }

    @include media(sm) {
        padding: 60px 0;
    }

    &.-large {
        padding: 120px 0;

        @include media(md) {
            padding: 90px 0;
        }

        @include media(sm) {
            padding: 70px 0;
        }
    }

    &.-small {
        padding: 60px 0;

        @include media(md) {
            padding: 50px 0;
        }

        @include media(sm) {
            padding: 40px 0;
        }
    }


    &.-skewed {
        margin: 90px 0;
        position: relative;
        color: $white;
        background: transparent !important;
        z-index: 0; // Need to set this reference for the :before element to have z-index:-1

        @include media(md) {
            margin: 90px 0;
        }

        @include media(sm) {
            margin: 80px 0;
        }

        &.-large {
            margin: 140px 0;

            @include media(md) {
                margin: 110px 0;
            }

            @include media(sm) {
                margin: 90px 0;
            }
        }

        &.-small {
            margin: 80px 0;

            @include media(md) {
                margin: 70px 0;
            }

            @include media(sm) {
                margin: 60px 0;
            }
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transform: skewY(-8deg);
            z-index: -1; // Need to set this so it goes behind content
        }

        &.-last {
            margin-bottom: 0;
            
            &:after {
                content: '';
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 50%;
                z-index: -1; // Need to set this so it goes behind content
            }
        }

        // &.-blue-dark, &.-red, &.-dark {
        //     color: $white;
        // }

        &.-white {
            color: $base-color;

            &:before, &:after {
                background: $white;
            }
        }

        &.-dark {
            &:before, &:after {
                background: $dark-color;
            }
        }

        &.-blue-dark {
            &:before, &:after {
                background: $blue-dark;
            }
        }

        &.-blue-darker {
            &:before, &:after {
                background: $blue-darker;
            }
        }

        &.-gray {
            &:before, &:after {
                background: $gray-700;
            }
        }

        &.-light-gray {
            &:before, &:after {
                background: $light-color;
            }
        }

        &.-gray-900 {
            &:before, &:after {
                background: $gray-900;
            }
        }

        &.-pink:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            background-image: linear-gradient(to bottom right, rgba(255, 255, 255, 0.7) 40%, rgba(144, 153, 159, 0.33) 75%, rgba(213, 73, 142, 0.5) 100%);
        }

        &.-pink:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) skewY(8deg);
            margin: 60px 0 0 90px;
            width: 600px;
            height: 200px;
            background-size: contain;
            z-index: 3;
            background: url($image-path + 'assets/img/backgrounds/ft-journey-offramp-friends-bg.png') top right no-repeat;
        }

        &.-red {
            &:before, &:after {
                background: linear-gradient(30deg, rgba(58, 109, 117, 1) 0%, rgba(203, 32, 39, 1) 50%, rgba(160, 80, 60, 1) 100%);
            }
        }
    }

    &.-path {
        position: relative;

        &:before, &:after {
            content: '';
            position: absolute;
            background: rgba($secondary-color, 0.7);

            @include media(md) {
                background: rgba($secondary-color, 0.3);
            }
        }

        &:before {
            top: 0;
            left: 50%;
            margin-left: -2px;
            width: 4px;
            height: 100%;
        }

        &:last-of-type:after {
            left: 50%;
            top: 100%;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            transform: translate(-50%, 0);
        }
    }
}

.ft2-page-surface {
    z-index: 1000;
    position: relative;
}

.ft2-page-overlay {
    min-width: 100vw;
    min-height: 100vh;
}

.ft-sections-nav {
    &.-light {
        color: $white;
    }
}

.map-bg {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%) skewY(8deg);
    width: 100%;
    height: 200%;
    min-width: 1600px;
    background: center center no-repeat;
    background-image: url($image-path + 'assets/img/backgrounds/ft-map-dotted.png');
    background-size: contain;
    opacity: 0.15;
}
