// margin and padding shorthands
$space-prefixes: (
    p : padding,
    pt : padding-top,
    pr : padding-right,
    pb : padding-bottom,
    pl : padding-left,
    m : margin,
    mt : margin-top,
    mr : margin-right,
    mb : margin-bottom,
    ml : margin-left,
    mx: margin,
    my: margin,
    py: padding,
    px: padding
) !default;



// main function definition
@mixin make-space($values, $prefixes, $breakpoints: $grid-breakpoints) {
    @each $breakpoint in map-keys($breakpoints) {
        $infix: breakpoint-infix($breakpoint, $breakpoints);

        @include media-breakpoint($breakpoint, $breakpoints) {
            @each $attr-short, $attr-long in $prefixes {
                @each $key, $value in $values {

                    @if (str-index($attr-short, 'x')) {
                        .#{$attr-short}#{$infix}-#{$key} {
                            #{$attr-long}-left: #{$value} !important;
                            #{$attr-long}-right: #{$value} !important;
                        }
                    } @else if (str-index($attr-short, 'y')) {
                        .#{$attr-short}#{$infix}-#{$key} {
                            #{$attr-long}-top: #{$value} !important;
                            #{$attr-long}-bottom: #{$value} !important;
                        }
                    } @else {
                        .#{$attr-short}#{$infix}-#{$key} {
                            #{$attr-long}: #{$value} !important;
                        }
                    }
                }
            }
        }
    }
}

@include make-space($space-values, $space-prefixes);
