@import "../globals/variables";
@import "../base/text-content";

// bootstrap variables
$popover-font-size: $base-font-size !default;
$popover-bg: $white !default;
$popover-max-width: 276px !default;
$popover-max-height: 250px !default;
$popover-border-width: 1px !default;
$popover-border-color: rgba($black, .2) !default;
$popover-border-radius: 4px !default;
$popover-inner-border-radius: 4 !default;
$popover-box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.5);
$popover-header-bg: $white;
$popover-header-color: $black !default;
$popover-header-padding-y: .5rem !default;
$popover-header-padding-x: .5rem !default;

$popover-body-color: $white !default;
$popover-body-padding-y: .5rem !default;
$popover-body-padding-x: .5rem !default;

$popover-arrow-width: 1rem !default;
$popover-arrow-height: .5rem !default;
$popover-arrow-color: $popover-bg !default;

$popover-arrow-outer-color: fade-in($popover-border-color, .05) !default;
$zindex-popover: 1070 !default;

.popover {
    position: absolute;
    top: 0;
    left: 0 #{"/* rtl:ignore */"};
    z-index: $zindex-popover;
    display: block;
    max-width: $popover-max-width;
    font-size: $base-font-size;
    // Allow breaking very long words so they don't overflow the popover's bounds
    word-wrap: break-word;
    background-color: $popover-bg;
    background-clip: padding-box;
    border: $popover-border-width solid $popover-border-color;
    border-radius: $popover-border-radius;
    box-shadow: $popover-box-shadow;

    .popover-arrow {
        position: absolute;
        display: block;
        width: $popover-arrow-width;
        height: $popover-arrow-height;

        &::before,
        &::after {
            position: absolute;
            display: block;
            content: "";
            border-color: transparent;
            border-style: solid;
        }
    }

    &.-no-padding {
        .popover-body {
            padding: 0;
        }
    }

    &.-no-max-width {
        max-width: none;
    }
}

// Offset the popover to account for the popover arrow
.popover-header {
    padding: $popover-header-padding-y $popover-header-padding-x;
    margin-bottom: 0; // Reset the default from Reboot
    font-size: $base-font-size;
    color: $popover-header-color;
    background-color: $popover-header-bg;
    border-bottom: $popover-border-width solid $popover-border-color;
    border-top-left-radius: $popover-inner-border-radius;
    border-top-right-radius: $popover-inner-border-radius;

    &:empty {
        display: none;
    }
}

.popover-body {
    padding: $popover-body-padding-y $popover-body-padding-x;
    color: $popover-body-color;
    max-height: $popover-max-height;
    overflow: auto;
}
