.ft2-perspective {
    perspective: 1000px;
}

.ft2-left-skew {
    transform: rotateX(5deg) rotateY(25deg) rotate(-3deg) translateZ(0);
}

.ft2-right-skew {
    transform: rotateX(5deg) rotateY(-25deg) rotate(3deg) translateZ(0);
}

.ft2-img-shadow{
    box-shadow: $img-shadow;
}

.ft2-elevate-1 {
    position: relative;
    z-index: 1;
}
