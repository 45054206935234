@import "../globals";

.ag-theme-material {
    .ag-header {
        font-weight: bold;
        text-transform: uppercase;
        font-family: $solid-family;
        color: #000;
        font-size: 13px;
        border-bottom: 1px solid $gray-200;
    }

    .ag-row {
        border: none;
        font-family: $text-family;
        font-size: 16px;
        font-weight: 300;
    }

    .ag-row-hover {
        background: #fafafa;
    }

    .ag-row-selected {
        background: $gray-100;
    }

    .ag-cell,
    .ag-header-cell,
    .ag-header-group-cell {
        padding-left: 15px;
        padding-right: 15px;
    }
}

//.ag-body-viewport,
//.ag-center-cols-viewport,
//.ag-center-cols-clipper {
//    overflow: visible;
//}
