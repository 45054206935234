.ft2-flipto-badge {
    display: block;
    width: 40px;
    height: 40px;
    background: url($image-path + 'assets/img/ft2-badge-logo.png') no-repeat center;
    background-size: cover;

    &.-inline {
        width: 2em;
        height: 2em;
    }
}