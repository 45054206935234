@mixin text-content($fz: $base-font-size) {
    font-size: $fz;
    line-height: 1.6;

    ul {
        list-style: disc;
        padding-left: 1.3em;
        margin-top: .5em;
        margin-bottom: .5em;
    }

    ol {
        list-style: decimal;
        padding-left: 1.3em;
        margin-top: .5em;
        margin-bottom: .5em;
    }

    strong, b {
        font-weight: bold !important;
    }

    i, em, blockquote {
        font-style: italic;
    }

    blockquote {
        border-left: 3px solid $gray-500;
        background: rgba(#000, .05);
        padding: 5px 5px 5px 15px;
    }

    table {
        width: 100%;
        text-align: left;

        th {
            font-weight: 600;
            border-bottom: 1px solid $gray-500;
        }
    }

    p {
        margin-bottom: 1em;
    }

    a {
        text-decoration: underline;
    }

    mark {

    }

    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6,
    .display-1, .display-2, .display-3, .display-4 {
        margin-bottom: 0.7em;
    }

    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6,
    .display-1, .display-2, .display-3, .display-4,
    p,
    figure,
    blockquote {
        + h1, + h2, + h3, + h4, + h5, + h6,
        + .h1, + .h2, + .h3, + .h4, + .h5, + .h6,
        + .display-1, + .display-2, + .display-3, + .display-4,
        + p,
        + figure,
        + blockquote {
            margin-top: 1em;
        }
    }

    p + button {
        margin-top: 1em;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    figure {
        width: auto !important;
    }

    figcaption {
        margin-top: 1em;
    }

    iframe {
        max-width: 100%;
    }

    .text-left {
        text-align: left;
    }

    .text-center {
        text-align: center;
    }

    .text-right {
        text-align: right;
    }
}

.text-content {
    @include text-content();
}
