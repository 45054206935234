.cdk-overlay-container{
    z-index: 3000;
}

.cdk-overlay-pane {
    position: relative !important;
    max-width: 100vw !important;
    width: 100%;
    height: 100%;
}

.cdk-global-scrollblock {
    overflow: hidden;
}

.cdk-global-overlay-wrapper {
    display: flex;
    position: absolute;
    z-index: 1000;
    overflow: auto;
    pointer-events: auto;
}

.cdk-overlay-dark-backdrop {
    background: rgba($dark-color, 0.98);
}

.cdk-overlay-backdrop {
    &.-light {
        background: $white;

        & + .cdk-global-overlay-wrapper .cdk-overlay-pane {
            .mat-dialog-container {
                padding: 0;
                color: $base-color;
            }
            .mat-mdc-dialog-container .mdc-dialog__surface {
                background: $white;
                color: $base-color;
            }
        }
    }

    &.-dark {
        background: rgba($dark-color, 0.98);
        color: $white;

        & + .cdk-global-overlay-wrapper .cdk-overlay-pane {
            .mat-dialog-container {
                padding: 0;
                color: $white;
            }
            .mat-mdc-dialog-container .mdc-dialog__surface {
                background: rgba($dark-color, 0.98);
                color: $white;
            }
        }
    }
}

.mat-dialog-container {
    background: none !important;
    color: inherit;
    box-shadow: none;
    overflow: visible !important;
    display: flex !important;
    justify-content: center;

    @include media(xs) {
        padding: 20px 0 !important;
    }

    .ft2-page-overlay {
        min-width: 100% !important;
    }
}

.ft2-dialog {
    &.-square {
        --mdc-dialog-container-shape: 0;
    }
}

.ft2-dialog_buttons {
    position: absolute;
    top: 10px;
    right: 15px;
    display: flex;
    align-items: center;
    z-index: 9;

    &.-fixed {
        position: fixed;
    }

    &.-dark{
        color: $white;
        transition: color $transition;
    }

    > *:not(:first-child) {
        margin-left: 20px;
    }
}

.ft2-light-dialog {
    position: relative;
    padding-top: 75px;
    min-height: 100vh;

    .ft2-light-dialog_controls {
        position: absolute;
        right: 0;
        top: 0;
        padding: 20px 75px 20px 20px;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: flex-end;
        background: $white;
        z-index: 1;

        > * {
            margin-left: 10px;
        }
    }

}

.ft2-dark-dialog {
    position: relative;
    min-height: 100vh;

    @include media(xs) {
        margin-top: -20px;
    }
}

