.ft2-control-panel {
    border-bottom: 1px solid $gray-200;
    margin-bottom: 45px;

    &_item {
        @include solid-family(13px);
        border-bottom: 2px solid $dark-color;
        display: inline-block;
        padding-bottom: 6px;
    }

    &-tabs {
        .mat-tab-group.mat-tab-group-nav .mat-tab-list {
            height: 30px;

            .mat-tab-label {
                padding: 0 !important;
                margin-right: 20px !important;
                margin-left: 0 !important;

                &:not(:has(.mat-tab-label-content > *)) {
                    display: none;
                }
            }
        }

        .mat-tab-nav-bar, .mat-tab-header {
            border: none !important;
        }

        .mat-tab-list:before {
            display: none;
        }
    }
}
