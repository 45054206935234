.ft2-browser {
    background-color: $gray-400;
    position: relative;
    border-radius: 4px;
    padding-top: 30px;
    display: flex;
    align-items: baseline;

    &_scroll {
        max-height: 400px;
        overflow: auto;
    }

    &.-small {
        padding-top: 18px;

        &:before {
            border-radius: 2px 2px 0 0;
            height: 18px;
            font-size: 8px;
        }
    }

    &.-dark {
        &:before {
            background: $gray-600;
            font-size: 14px;
            color: rgba(255, 255, 255, 0.2);
            box-shadow: 18px 0 0 rgb(255 255 255 / 20%), 36px 0 0 rgb(255 255 255 / 20%);
            height: 100%;
            padding-left: 15px;
        }
    }

    &.-shadow {
        box-shadow: 0 1px 3px 2px rgba($dark-color, 0.15);
    }

    &:before {
        content: '⬤ ⬤ ⬤';
        text-align: left;
        position: absolute;
        font-family: monospace;
        border-radius: 4px 4px 0 0;
        height: 30px;
        width: 100%;
        background: $gray-300;
        color: $gray-500;
        left: 0;
        top: 0;
        font-size: 10px;
        padding-left: 5px;
        display: flex;
        align-items: center;
    }

    img {
        width: 100%;
    }
}

.ft2-icn-scrollable {
    text-indent: initial;
    line-height: 1;
    position: absolute;
    bottom: 9px;
    right: 9px;
  }
  .ft2-icn-scrollable:before,
  .ft2-icn-scrollable:after {
    content: "";
    font-size: 1.5em;
  }
  .ft2-icn-scrollable:before {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 0.6em;
    height: 1em;
    box-shadow: inset 0 0 0 0.06em;
    border-radius: 1em;
  }
  .ft2-icn-scrollable:after {
    position: absolute;
    top: 0.2em;
    left: 0.2em;
    width: 0.2em;
    height: 0.2em;
    border-radius: 0.2em;
    box-shadow: inset 0 0 0 1em;
    animation: ft-icn-scrollable-loop 1.5s cubic-bezier(0.7, 0, 0.1, 1) infinite both;
  }
  @keyframes ft2-icn-scrollable-loop {
    0% {
      opacity: 0;
      transform: translateY(0) scale(4);
    }
    20% {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
    80% {
      transform: translateY(200%);
    }
    100% {
      opacity: 0;
    }
  }

.ft2-preview {
    position: relative;
    max-width: 960px;
    margin: 0 auto 30px;
    background: #fff;
    box-shadow: 0 1px 4px rgb(0 0 0 / 50%);
    border-radius: 4px;

    .preview-chrome {
        position: relative;
        line-height: 1;
        height: 40px;
        font-family: brandon-grotesque, sans-serif;
        font-weight: bold;
        text-transform: uppercase;
        text-align: center;
        padding: 10px;
        color: rgba(255, 255, 255, 0.4);
        background: #60696f;
        border-radius: 4px 4px 0 0;
    }

    .preview-content {
        min-height: 400px;
        color: #000;
    }
}

.preview-options {
    width: 100%;
    padding: 10px 15px;
    background-color: #e3e6e9;
    color: #90999f;

    .launch-preview {
        float: right;
        margin-left: 15px;
    }

    a.launch-preview {
        text-decoration: none !important;
        cursor: pointer;
        color: #4A90E2 !important;
    }
}

.ft2-preview-banner {
    background-color: #f3f3f6;
    width: 100%;
    min-height: 150px;
    padding: 30px;
    margin-bottom: 20px;
    text-align: center;
    border: 1px solid #e0e0e0;
}
