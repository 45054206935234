@import "../globals";

.ft2-hub-body {

    .ft2-header {
        &_title {
            max-width: 700px;
            margin: auto;
            font-size: 32px;
            font-weight: 500;
        }

        &_subtitle {
            @include solid-family($letter-spacing: .1em);
            line-height: 1;
            font-size: 13px;
        }

        &_stats-title {
            font-size: 24px;

            @include media(sm) {
                font-size: 16px;
            }

            &.-text-huge {
                font-size: 32px;

                @include media(sm) {
                    font-size: 24px;
                }
            }
        }

        &_text {
            font-size: 18px;

            @include media(sm) {
                font-size: 16px;
            }
        }

        &_tooltip {
            font-size: 13px;
            line-height: 1.35;
        }

        &_light-shadow-bg {
            width: 100%;
            position: relative;

            &:before {
                content: "";
                position: absolute;
                width: 500px;
                height: 500px;
                transform: translate(-50%, 50%) scaleX(2);
                left: 50%;
                bottom: 0;
                background: radial-gradient(circle, rgba(255, 255, 255, .8) 20%, rgba(255, 255, 255, 0) 66%);
                z-index: -1;
                max-width: 50vw; // Fix overflow issue - extra y-scroll on mobile
                max-height: 100vw;

                @include media(md) {
                    width: 300px;
                    height: 300px;
                }
            }
        }
    }

    .ft2-bg-image {
        position: absolute;
        top: 0;
        bottom: unset;
        left: 50%;
        right: unset;
        width: 0;
        height: 0;
        background: 50% no-repeat;
        background-size: cover;
        z-index: -1;
        transform: rotate(30deg) skew(-10.9deg, -9deg);

        [class*="ft2-photo-"] {
            position: absolute;
            top: 0;
            left: 50%;
            width: 600px;
            background: center center no-repeat;
            background-size: cover;
            box-shadow: 10px 10px 14px rgba(0, 0, 0, .3);
            animation: ft2-photo-enter 1s cubic-bezier(0, 0, 0.1, 1) both;

            &:before {
                content: "";
                display: block;
                padding-top: 75%;
            }

            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                width: 100%;
                height: 100%;
                background-image: linear-gradient(165deg, rgba(0, 0, 0, .3), rgba(48, 57, 63, .1));
            }
        }

        @include media(md) {
            transform: rotate(30deg) skew(-10.9deg, -9deg) scale(50%) translate(-40px, 34px)
        }

        .ft2-photo-1.ft2-fallback-image {
            // If org has no image assets available, show this
            background-image: url($image-path + 'assets/backgrounds/header/ft-hub-header-fallback-photo.jpg');
        }

        &.ft2-photo-layout-1 {
            .ft2-photo-1 {
                width: 625px;
                margin: -115px 0 0px -214px;
                animation-delay: .2s;
            }

            .ft2-photo-2,
            .ft2-photo-3,
            .ft2-photo-4,
            .ft2-photo-5 {
                display: none;
            }
        }

        &.ft2-photo-layout-3 {
            .ft2-photo-1 {
                width: 484px;
                margin: -104px 0 0px -227px;
                animation-delay: .2s;
            }

            .ft2-photo-2 {
                margin: 75px 0 0 -630px;
                width: 378px;
                animation-delay: .3s;
            }

            .ft2-photo-3 {
                margin: -297px 0 0 280px;
                width: 360px;
                animation-delay: .4s;
            }

            .ft2-photo-4,
            .ft2-photo-5 {
                display: none;
            }
        }

        &.ft2-photo-layout-5 {
            .ft2-photo-1 {
                width: 425px;
                margin: -134px 0 0px -170px;
                animation-delay: .2s;
            }

            .ft2-photo-2 {
                margin: 222px 0 0 -220px;
                width: 255px;
                animation-delay: .3s;
            }

            .ft2-photo-3 {
                margin: -6px 0 0 300px;
                width: 255px;
                animation-delay: .4s;
            }

            .ft2-photo-4 {
                margin: 90px 0 0 -612px;
                width: 360px;
                animation-delay: .5s;
            }

            .ft2-photo-5 {
                margin: -276px 0 0 279px;
                width: 320px;
                animation-delay: .6s;
            }
        }
    }

    .ft2-bg-matte {
        height: 50%;

        .ft2-summary {
            position: relative;
            margin-top: 2rem;
            padding-top: 2rem;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 66%;
                border-top: 1px solid #fff;
                opacity: .3;
            }
        }
    }

    .ft2-boilerplate-section {
        position: relative;

        .ft2-org-description {
            padding: 15px;
            text-align: center;
            background-color: #fff;

            .ft2-summary {
                max-width: 40em;
                margin: 0 auto;
            }
        }
    }

    .ft2-intro-section {
        text-align: center;

        .ft2-summary {
            max-width: 40em;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .ft2-intro-carousel-cards {
        .ft-carousel-3d {
            --ft-carousel-3d-offset-x: 45%;
            --ft-carousel-3d-offset-x-step: 1;
            --ft-carousel-3d-offset-z: 300px;
            --ft-carousel-3d-offset-z-step: 1;
            --ft-carousel-3d-gap: 20px;
            --ft-carousel-3d-rotate-y: 45deg;
        }
    }

    .ft2-intro-carousel-card {
        &__overlay {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 1;
        }
    }

    .ft2-bigpicture-section {
        padding-top: 0;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #fff 25%);
    }

    .ft2-bigpicture-share {
        p {
            max-width: 30em;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .ft2-bigpicture-results {
        .ft-text-fineprint {
            margin-top: 0;
        }

        .ft2-stat {
            position: relative;
            padding-top: 60px;

            [class*="ft-icn-bullet-"] {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -70%);
                width: 100px;
                height: 100px;
                opacity: .2;
            }
        }

        .ft2-count,
        .ft2-count-label {
            position: relative;
        }
    }

    .ft2-section-bg {
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        transform: skewY(-8deg);
        background: #40494f;
    }

    .ft2-bookings-breakdown-section {
        position: relative;
        color: $white;
    }
}

@keyframes ft2-photo-enter {
    0% {
        opacity: 0;
        transform: translate3d(-70px, -100px, 0);
    }

    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}
