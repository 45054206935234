@import '../globals';

.ft2-touchpoint-entry {
    padding: 15px;
    border: 1px solid $gray-200;
    background: $white;
    margin-top: 15px;
    font-weight: 500;
    cursor: pointer;
    transition: box-shadow $transition;
    display: flex;
    align-items: center;
    line-height: 1.2;

    &.-disabled {
        background: $gray-100;
        position: relative;

        &:after {
            content: 'Off';
            position: absolute;
            left: -1px;
            top: -1px;
            padding: 2px 10px;
            font-size: 14px;
            color: #fff;
            background: $gray-700;
            border-radius: 0 0 4px 0;
        }
    }

    mat-icon {
        width: 36px;
        min-width: 36px;
        height: 36px;
        margin-right: 15px;
    }

    &:hover {
        box-shadow: 0 10px 10px -5px rgba(0, 0, 0, .2);
    }
}

//.ft-touchpoint-wrapper {
//    position: fixed;
//    left: 0;
//    right: 0;
//    bottom: 0;
//    top: 0;
//    overflow: auto;
//    background: $white;
//    z-index: 100;
//
//    .ft-section {
//        padding: 90px 0 !important;
//
//        @include media(md) {
//            padding: 60px 0 !important;
//        }
//    }
//}

.ft-touchpoint-close-btn {
    float: right;
    color: $white;
    opacity: 0.5;
    z-index: 1;
    transition: all .2s ease;

    &:hover {
        opacity: 1;
        color: $white;
    }
}

.ft-touchpoint-header {
    min-height: 120px;
    color: $white;
    background: #20292f;
    // margin-bottom: 20px;

    &_container {
        min-width: 400px;
        width: 100%;
        padding: 20px 30px;
        margin: 0 auto;

        @include media(md) {
            padding: 20px 15px;
        }
    }

    &_title {
        font-weight: 100;
    }
}

.ft-touchpoint-nav {
    height: 60px;
    width: 100%;
    background: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid $gray-400;
    margin: 0;
    padding: 0;
    font-size: $medium-font-size;

    &_tab {
        height: 100%;
        padding: 0 10px;
        margin: 0 15px;
        text-transform: uppercase;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $gray-500;
        position: relative;
        cursor: pointer;
        transition: color .1s linear;
        font-size: $medium-font-size;

        &:hover {
            color: $gray-800;
        }

        &.-active {
            color: $gray-800;

            &:after {
                content: '';
                position: absolute;
                top: 100%;
                height: 2px;
                width: 100%;
                left: 0;
                background: $gray-800;
            }
        }
    }
}

.ft-touchpoint-components {
    width: 100%;
    display: flex;
    overflow: auto;
    flex-wrap: nowrap;
    padding: 10px 30px 30px;

    @include media(md) {
        padding: 10px 15px 30px;
    }

    > ft-permissions {
        margin-right: auto;
    }
}

.ft-touchpoint-component {
    background: $white;
    padding: 15px;
    width: 260px;
    max-width: 260px;
    height: 140px;
    text-align: center;
    opacity: 0.5;
    display: inline-flex;
    margin: 0 5px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $base-color;
    cursor: pointer;
    position: relative;

    &:first-child {
        margin-left: auto;
    }

    &:last-child {
        margin-right: auto;
    }

    &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 1;
    }

    &.-active {
        opacity: 1;

        &:before {
            display: none;
        }
    }

    span {
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    strong {
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        mat-icon {
            position: relative;
            top: 3px;
        }
    }
}

.ft-touchpoint-component-create {
    background: none;
    border: 1px dashed $white;
    height: 140px;
    min-width: 140px;
    color: $white;
    cursor: pointer;
    opacity: 0.5;

    &:hover {
        opacity: 1;
    }
}

.ft-touchpoint-panel.inactive {
    opacity: 0.5;
    cursor: default !important;

    &:hover {
        box-shadow: none !important;
        cursor: default !important;
    }
}

.ft2-editor-content {
    border-radius: 4px;
    padding: 10px 5px;

    &:hover {
        background-color: rgba(0, 101, 155, 0.1);
        box-shadow: 0 0 1px rgb(0 101 155 / 50%), 0 1px 4px 0 rgb(0 101 155 / 20%);
    }
}
