.ft2-link {
    cursor: pointer;
    @include underline-opacity();
    white-space: nowrap;
}

.ft2-link-arrow {
    &::after {
        @include icon;
        content: "arrow_right_alt";
        margin-left: 3px;
        display: inline-block;
    }
}
